import { format } from 'date-fns'
import { FC, useEffect } from 'react'
import {
  ArrowPosition,
  Card,
  CardSpace,
  CardStyle,
  Loader,
  SeeMore,
  Table,
  Td,
  Tooltip,
  TooltipPosition,
  Tr
} from '../../../../components'
import { Refresh } from '../../../../components/Icons'
//@ts-ignore
import { NavLink, useParams } from 'react-router-dom'
//@ts-ignore
//@ts-ignore
import { showToast } from '@nodus/utilities-front'
import {
  Check,
  Clock,
  Failed,
  Pending,
  Processing
} from '../../../../components/Icons'
import {
  MODELS_PATH,
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS
} from '../../../../constants'
import { useCurrentPage, useFetch } from '../../../../hooks'
import { history } from '../../../../utils'
import { useModelDetailsContext } from '../../context/ModelDetailsContext'
import { handleErrors } from '../../utils/handle-errors'

export const DataImportDetails: FC = () => {
  let { modelId } = useParams()
  const { pipelineData, pipelineLoading } = useModelDetailsContext()
  const {
    apiCall: getPipelineRuns,
    loading: runsLoading,
    response: pipelineRuns
  } = useFetch('get')
  const { apiCall: runPipelineJob, loading: runPipelineLoading } =
    useFetch('post')

  const { currentPage, handlePageChange } = useCurrentPage()
  const totalItems = !!pipelineRuns && pipelineRuns?.data?.totalItems
  const PER_PAGE = 10

  const getPipelineRunsData = () => {
    getPipelineRuns(
      `${USER_PROFILE_API}/integration-models/rest-pipeline-job-runs?integrationStructureId=${modelId}&page=${
        currentPage - 1
      }`,
      USER_PROFILE_API_HEADERS
    )
  }

  const runPipeline = () => {
    runPipelineJob(
      `${USER_PROFILE_API}/integration-models/pipeline-job-run?integrationStructureId=${modelId}`,
      {},
      () => {
        showToast('success', 'Pipeline Job ran successfully!')
        getPipelineRunsData()
      },
      (err) => {
        handleErrors(err)
      },
      USER_PROFILE_API_HEADERS
    )
  }

  useEffect(() => {
    getPipelineRunsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, currentPage])

  const statusIcons = (status: string) => {
    switch (status) {
      case 'Failed':
        return <Failed width="20" height="20" />
      case 'Succeeded':
        return <Check width="20" height="20" />
      case 'Running':
        return <Processing width="20" height="20" fill="#fff" />
      case 'Pending':
        return <Pending width="20" height="20" />
    }
  }

  const formatDuration = (duration: string) => {
    let formattedDuration = ''
    const [day, hour, minute, second] = duration.split(':')

    if (+day) formattedDuration += `${+day}d `
    if (+hour || (+day && (+minute || +second)))
      formattedDuration += `${+hour}h `
    if (+minute || (+second && (+hour || +day)))
      formattedDuration += `${+minute}m `
    if (+second) formattedDuration += `${+second}s`

    if (!formattedDuration) formattedDuration = '0s'

    return formattedDuration
  }

  if (pipelineLoading) {
    return <Loader />
  }

  return (
    <Card
      cardStyle={CardStyle.bordered}
      cardSpace={CardSpace.xl}
      className="mt-8 data-import-tab"
    >
      {!pipelineLoading && totalItems && (
        <>
          <div className="mb-10 flex justify-between">
            <div>
              <h3 className="text-xl text-primary-mainText font-medium mb-2">
                Schedule
              </h3>
              <p className="text-sm font-medium text-primary-mainText">
                {pipelineData?.scheduleText}
              </p>
            </div>
            <div className="flex gap-2">
              <button
                type="button"
                onClick={() => {
                  history.push(`${MODELS_PATH}/${modelId}/data-import`)
                }}
                className="text-primary-mainText bg-gray-50 border border-primary-stroke hover:bg-gray-200 text-sm font-medium px-4 rounded h-8 focus:shadow-outlineGray"
              >
                Edit
              </button>
              <button
                type="button"
                onClick={runPipeline}
                disabled={runPipelineLoading}
                className="text-white bg-primary border border-primary hover:bg-primary-hover text-sm font-medium px-4 rounded h-8 focus:shadow-outlinePrimary"
              >
                Run pipeline
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <h3 className="text-xl text-primary-mainText font-medium mt-4">
              Import executions
            </h3>
            <button type="button" onClick={getPipelineRunsData}>
              <span className="w-8 h-8 flex items-center justify-center shadow rounded cursor-pointer hover:bg-gray-50 transition">
                <Refresh size={18} />
              </span>
            </button>
          </div>
          <Table
            loading={runsLoading}
            names={[
              { children: 'Message' },
              { children: 'Execution Time' },
              { children: 'Duration' }
            ]}
            values={pipelineRuns?.data?.models}
            renderTr={(el: any) => {
              return (
                <Tr>
                  <Td>
                    <div className="flex gap-2">
                      <Tooltip
                        text={el?.status}
                        innerClassName="h-8 mb-0 w-fit-content z-50 pb-3 top-5"
                        className="text-sm text-primary-secText ml-2 w-6"
                        tooltipPosition={TooltipPosition.underTooltip}
                        arrowPosition={ArrowPosition.top}
                        bottom="bottom-0"
                        preserveText
                      >
                        {statusIcons(el?.status)}
                      </Tooltip>
                      <SeeMore fullText={el?.message} className="text-sm" />
                    </div>
                  </Td>
                  <Td className=" text-primary-mainText">
                    {el?.lastModified
                      ? format(
                          new Date(el?.lastModified),
                          'MMMM dd, yyyy - HH:mm'
                        )
                      : ''}
                  </Td>
                  <Td className="flex gap-2 items-center text-primary-mainText">
                    <Clock width="20" height="20" />
                    {el?.duration ? formatDuration(el?.duration) : 'Ongoing'}
                  </Td>
                </Tr>
              )
            }}
            pagination={{
              pageSize: PER_PAGE,
              totalCount: totalItems,
              onPageChange: handlePageChange,
              page: currentPage,
              length: PER_PAGE
            }}
            emptyView={{ text: 'There are no runs yet.' }}
          />
        </>
      )}
      {!pipelineLoading && !runsLoading && !pipelineRuns?.data && (
        <div className="mt-28 flex justify-center flex-col items-center">
          <h3 className="text-xl text-primary-mainText mb-4">
            No data import created yet.
          </h3>
          <NavLink
            to={`${MODELS_PATH}/${modelId}/data-import`}
            className="text-md text-primary-300"
          >
            <span className="text-xl mr-1">+</span>Create Data Import
          </NavLink>
        </div>
      )}
    </Card>
  )
}
