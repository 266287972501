import { FC, ReactElement, ReactNode, useRef, useState } from 'react'

//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { Animation } from '../../components'
import { cx } from '../../utils'

interface IAccordion {
  title: ReactNode
  content: ReactElement
  className?: string
  buttonClassName?: string
  accordionClassName?: string
  opened?: boolean
  noAnimation?: boolean
}

export const Accordion: FC<IAccordion> = (props) => {
  const [active, setActive] = useState<boolean>(props.opened || false)
  // const [height, setHeight] = useState<string>("0px");

  const content = useRef<any>(null)

  const toggleAccordion = () => {
    setActive(!active)
    // setHeight(active ? "0px" : `${content.current?.scrollHeight}px`);
  }

  return (
    <div className={cx(['flex flex-col', props.className])}>
      <button
        type="button"
        className={cx([
          'outline-none text-left flex justify-between text-primary-mainText border border-primary-stroke p-4 focus:outline-none rounded bg-primary-bg',
          active && 'rounded-b-none',
          'hover:text-primary',
          props.buttonClassName
        ])}
        onClick={toggleAccordion}
      >
        {props.title}

        <span className="inline-block w-4 h-4">
          <Icon name={active ? 'ArrowUp' : 'ArrowDown'} size={18} />
        </span>
      </button>

      <Animation
        duration={300}
        show={props.noAnimation ? true : active}
        type="fade-top"
      >
        <div
          ref={content}
          style={{
            // maxHeight: `${height}`,
            // transition: "max-height .2s ease-in",
            // overflow: "auto",
            display: active ? 'block' : 'none'
          }}
        >
          <div
            className={cx([
              'p-4 border-l border-b border-r border-primary-stroke bg-white',
              props.accordionClassName
            ])}
          >
            {props.content}
          </div>
        </div>
      </Animation>
    </div>
  )
}

export default Accordion
