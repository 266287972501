import { RouteProps } from 'react-router-dom'
import {
  GLOBAL_CSS_PATH,
  MODELS_CREATE_PATH,
  MODELS_PATH,
  TEMPLATES_PATH,
  UNIVERSAL_LOGIN_PATH
} from '../constants'
import {
  Branding,
  BrandingCreate,
  Brandings,
  CostumizationTemplate,
  DataImport,
  DetailsView,
  GlobalCSS,
  ModelCreate,
  ModelEdit,
  Models,
  Templates
} from '../pages'
import { ModelOptions } from '../pages/models/components'
import { ModelDetailsContextProvider } from '../pages/models/context/ModelDetailsContext'
type BreadcrumbsPropsItem = {
  name?: string
  to?: string
}
enum Permission {
  admin = 'SuperAdmin',
  usersEditor = 'EditorUsers',
  appsEditor = 'EditorSpecificApps'
}

const { admin, appsEditor } = Permission

export interface RoutesProps extends RouteProps {
  name?: string
  crumbs?: BreadcrumbsPropsItem[]
  permissions?: Permission[]
}

export const applicationsRoutes: RoutesProps[] = [
  {
    path: UNIVERSAL_LOGIN_PATH,
    element: <Brandings />,
    name: 'Brandings',
    crumbs: [{ name: 'Customization', to: UNIVERSAL_LOGIN_PATH }],
    permissions: [admin]
  },
  {
    path: `${UNIVERSAL_LOGIN_PATH}/create`,
    element: <BrandingCreate />,
    name: 'Brandings',
    crumbs: [
      { name: 'Customization', to: UNIVERSAL_LOGIN_PATH },
      { name: 'Create' }
    ],
    permissions: [admin]
  },
  {
    path: `${UNIVERSAL_LOGIN_PATH}/:id`,
    element: <Branding />,
    name: 'Brandings',
    crumbs: [
      { name: 'Customization', to: UNIVERSAL_LOGIN_PATH },
      { name: 'Edit' }
    ],
    permissions: [admin]
  },
  {
    path: `${TEMPLATES_PATH}`,
    element: <Templates />,
    name: 'Templates',
    crumbs: [{ name: 'Customization' }, { name: 'Templates' }],
    permissions: [admin]
  },
  {
    path: `${TEMPLATES_PATH}/:id`,
    element: <CostumizationTemplate />,
    name: 'Templates',
    crumbs: [
      { name: 'Customization' },
      { name: 'Templates', to: TEMPLATES_PATH },
      { name: 'Edit' }
    ],
    permissions: [admin]
  },
  {
    path: GLOBAL_CSS_PATH,
    element: <GlobalCSS />,
    name: 'Global CSS',
    crumbs: [
      { name: 'Customization' },
      { name: 'Templates', to: TEMPLATES_PATH },
      { name: 'Global CSS' }
    ],
    permissions: [admin]
  },
  {
    path: MODELS_PATH,
    element: <Models />,
    name: 'Models',
    crumbs: [{ name: 'Customization' }, { name: 'Models' }],
    permissions: [admin]
  },
  {
    path: MODELS_CREATE_PATH,
    element: <ModelCreate />,
    name: 'Models',
    crumbs: [
      { name: 'Customization' },
      { name: 'Models', to: MODELS_PATH },
      { name: 'Create' }
    ],
    permissions: [admin]
  },
  {
    path: `${MODELS_PATH}/:modelId/*`,
    element: (
      <ModelDetailsContextProvider>
        <DetailsView />
      </ModelDetailsContextProvider>
    ),
    name: 'Models Edit',
    crumbs: [{ name: 'Models', to: `${MODELS_PATH}` }, { name: 'Details' }],
    permissions: [admin, appsEditor]
  },
  {
    path: `${MODELS_PATH}/:modelId/edit`,
    element: <ModelEdit />,
    name: 'Models',
    crumbs: [{ name: 'Models', to: MODELS_PATH }, { name: 'Edit' }],
    permissions: [admin]
  },
  {
    path: `${MODELS_PATH}/:modelId/data-import`,
    element: <DataImport />,
    name: 'Models',
    crumbs: [
      { name: 'Models', to: MODELS_PATH },
      { name: 'Create Data Import' }
    ],
    permissions: [admin]
  },
  {
    path: `${MODELS_PATH}/:modelId/model-options`,
    element: (
      <ModelDetailsContextProvider>
        <ModelOptions />
      </ModelDetailsContextProvider>
    ),
    name: 'Models',
    crumbs: [{ name: 'Models', to: MODELS_PATH }, { name: 'Model Options' }],
    permissions: [admin]
  }
]
