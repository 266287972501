import { css } from '@codemirror/lang-css'
import { javascript } from '@codemirror/lang-javascript'

import CodeMirror from '@uiw/react-codemirror'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useSearchParams } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import { BackButton, FormFooter, Loader, PageHeadeline } from '../../components'
import {
  TEMPLATES_API,
  TEMPLATES_PATH,
  USER_PROFILE_URL
} from '../../constants'
import { useFetch, useToggle } from '../../hooks'
import { EditorTab, SaveChangesModal } from './components'
import { PreviewButton } from './components/PreviewButton'
import { ResetDropdown } from './components/ResetDropdown'
import {
  CurrentLangTypes,
  ILanguage,
  ITemplate,
  languageIState
} from './interface'
import { redirectRoute } from './utils/redirect-routes'

export const CostumizationTemplate = () => {
  const { id } = useParams()
  const [currentLang, setCurrentLang] = useState<CurrentLangTypes>('jsx')
  const [searchParams, setSearchParams] = useSearchParams({})
  const [template, setTemplate] = useState<ITemplate>()
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [language, setLanguage] = useState<ILanguage>(languageIState)

  const { toggle: saveChangesModalToggle, visible: saveChangesModalVisible } =
    useToggle()

  const {
    apiCall: getTemplate,
    response: templateResponse,
    loading
  } = useFetch('get')
  const { apiCall: updateTemplate, loading: updateTemplateLoading } =
    useFetch('put')

  useEffect(() => {
    id && getTemplate(`${TEMPLATES_API}/${id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    templateResponse && setTemplate(templateResponse)
  }, [templateResponse])

  useEffect(() => {
    if (template) {
      setLanguage({
        ...language,
        jsx: template?.previewHtml || languageIState.jsx,
        css: template?.previewCss || ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template])

  const searchParam = searchParams.get('language')
  const searchParamType = searchParams.get('type')

  useEffect(() => {
    searchParam && setCurrentLang(searchParam as CurrentLangTypes)
  }, [searchParam])

  const onJsxEditorChange = useCallback(
    (value: string) => {
      setIsDirty(true)
      setLanguage({
        ...language,
        jsx: value
      })
    },
    [language]
  )

  const onCssEditorChange = useCallback(
    (value: string) => {
      setIsDirty(true)
      setLanguage({
        ...language,
        css: value
      })
    },
    [language]
  )

  const onTemplateResetToDefault = () => {
    setIsDirty(true)
    currentLang === 'jsx'
      ? setLanguage({ ...language, jsx: template?.defaultHtml || '' })
      : setLanguage({ ...language, css: template?.defaultCss || '' })
    showToast('success', 'Template has been reset to its default state!')
  }

  const onTemplateResetToLast = () => {
    setIsDirty(true)
    currentLang === 'jsx'
      ? setLanguage({ ...language, jsx: template?.html || '' })
      : setLanguage({ ...language, css: template?.css || '' })
    showToast('success', 'Template has been reset to its published state!')
  }

  const onTemplateFormSubmit = () => {
    const templateObj = {
      ...template,
      html: language?.jsx,
      css: language?.css,
      previewCss: language?.css,
      previewHtml: language?.jsx
    }

    updateTemplate(
      `${TEMPLATES_API}/${id}`,
      templateObj,
      (response) => {
        showToast('success', 'Template updated successfully!')
        setTemplate(response)
        setIsDirty(false)
        saveChangesModalToggle()
      },
      (err) => {
        console.log('error', err)
      }
    )
  }

  // Update default html or css (only for devs!)
  // const onTemplateDefaultFormSubmit = () => {
  //   const templateObj = {
  //     ...template,
  //     defaultHtml: language?.jsx,
  //     defaultCss: language?.css,
  //   };

  //   updateTemplate(
  //     `https://templates.porta.gjirafa.dev/api/v2/templates/${id}`,
  //     templateObj,
  //     () => {
  //       showToast('success', "Template updated successfully!");
  //     },
  //     (err) => {
  //       console.log("error", err);
  //     }
  //   );
  // };

  const onTemplatePreview = () => {
    const redirectPath =
      template?.type === 'Custom'
        ? `${USER_PROFILE_URL}/${'custom-page/'}${
            template?.alias || ''
          }?mode=preview`
        : `${USER_PROFILE_URL}/${
            redirectRoute[template?.alias || ''] || ''
          }?template=${template?.alias}&mode=preview` || ''

    template?.alias && window.open(redirectPath, '_blank')

    const templateObj = {
      ...template,
      previewHtml: language?.jsx,
      previewCss: language?.css
    }

    updateTemplate(
      `${TEMPLATES_API}/${id}`,
      templateObj,
      (response) => {
        setTemplate(response)
      },
      (err) => {
        console.log('error', err)
      }
    )
  }

  const renderCurrentLangTemplate = (lang: CurrentLangTypes) => {
    setCurrentLang(lang)
    setSearchParams({ language: lang })
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="h-full flex flex-col">
      <Helmet>{language?.css && <style>{template?.css || ''}</style>}</Helmet>
      <div>
        <BackButton
          to={`${TEMPLATES_PATH}${
            searchParamType ? `?type=${searchParamType}` : ''
          }`}
          label="Back to templates"
          children={undefined}
        />
        <PageHeadeline title={template?.name || ''} description="" />
      </div>
      <div className="border border-primary-stroke rounded mt-6">
        <div className="border-b border-primary-stroke py-6 px-4 flex justify-between bg-white">
          <div>
            <EditorTab
              title="JSX"
              isCurrent={currentLang === 'jsx'}
              onClick={() => renderCurrentLangTemplate('jsx')}
            />

            <EditorTab
              title="CSS"
              isCurrent={currentLang === 'css'}
              onClick={() => renderCurrentLangTemplate('css')}
            />
          </div>

          <div className="flex items-center gap-4">
            <PreviewButton onClick={onTemplatePreview} />
            <ResetDropdown
              onDefaultReset={onTemplateResetToDefault}
              onPreviousReset={onTemplateResetToLast}
            />
          </div>
        </div>

        <div className="editor__container">
          {currentLang === 'jsx' ? (
            <CodeMirror
              value={language?.jsx}
              theme="dark"
              extensions={[javascript({ jsx: true })]}
              onChange={onJsxEditorChange}
            />
          ) : (
            <CodeMirror
              value={language?.css}
              theme="dark"
              minHeight="300px"
              extensions={[css()]}
              onChange={onCssEditorChange}
            />
          )}
        </div>

        <FormFooter
          loading={updateTemplateLoading}
          onSubmit={saveChangesModalToggle}
          disabled={!isDirty}
          mtAuto
          pb="6"
          pt="6"
          className="py-6 px-4 sticky bottom-0 bg-white"
          saveBtnText="Save & Publish"
        />
      </div>
      <SaveChangesModal
        isVisible={saveChangesModalVisible}
        toggleModal={saveChangesModalToggle}
        onSubmit={onTemplateFormSubmit}
        loading={updateTemplateLoading}
        templateName={`${template?.name} template` || ''}
      />
    </div>
  )
}
