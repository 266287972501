import { FC } from 'react'
import { IHeaders } from '../../interface'

interface IRequestHeadersDetails {
  headers: IHeaders[] // check this
}

export const RequestHeadersDetails: FC<IRequestHeadersDetails> = ({
  headers
}) => {
  return (
    <>
      <p className="text-primary-mainText">Headers</p>
      {!!headers?.length && (
        <>
          <div className="flex w-full lg:w-3/5 gap-4 py-2 items-center border-primary-stroke pb-1">
            <p className="text-primary-secText font-medium w-1/2 text-sm">
              Header Name
            </p>
            <p className="text-primary-secText font-medium w-1/2 text-sm">
              Header Value
            </p>
          </div>
          {headers?.map((header: IHeaders, index: number) => (
            <div className="flex w-full lg:w-3/5 gap-4 py-2" key={index}>
              <div className="mb-6 w-1/2">
                <p className="text-sm text-primary-mainText break-all">
                  {header?.key}
                </p>
              </div>
              <div className="mb-6 w-1/2">
                <p className="text-sm text-primary-mainText break-all">
                  {header?.value}
                </p>
              </div>
            </div>
          ))}
        </>
      )}
      {!headers?.length && (
        <p className="text-sm italic text-primary-mainText mb-6 mt-2">
          No headers to display
        </p>
      )}
    </>
  )
}
