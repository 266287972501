import {
  ActionOutputDetails,
  RequestHeadersDetails,
  VisibilitySettingsDetails
} from '.'
import { Accordion, Label } from '../../../../components'
import { requestMethodTypes } from '../../dummy-data'
import { ModelDetailsItem } from './ModelDetailsItem'

export const ModelActionsDetails = (props: any) => {
  return (
    <div>
      <h3 className="text-xl text-primary-mainText font-medium mb-4 mt-10">
        Model Actions
      </h3>
      {props?.actions?.map((action: any, index: number) => {
        return (
          <div className="flex justify-between mb-6" key={index}>
            <Accordion
              title={action?.name || `Action ${index + 1}`}
              className="flex-1"
              key={index}
              opened={index === 0 ? true : false}
              content={
                <>
                  <ModelDetailsItem
                    label="Model Action Type"
                    value={action?.actionType?.name}
                  />

                  {(action?.actionType?.name === 'Hyperlink' ||
                    action?.actionType?.name === 'Button') && (
                    <>
                      <ModelDetailsItem label="URL" value={action?.url} />

                      {/* Visibility Settings */}
                      {action?.showVisibilitySettings && (
                        <VisibilitySettingsDetails
                          visibilitySettings={action?.visibilitySettings}
                        />
                      )}
                    </>
                  )}

                  {/* Headers */}
                  {action?.actionType?.name === 'Button' && (
                    <>
                      <RequestHeadersDetails headers={action?.headers} />

                      <ModelDetailsItem
                        label="Request Method"
                        value={
                          requestMethodTypes?.find(
                            (methodType) =>
                              methodType?.value === action?.methodType
                          )?.label || ''
                        }
                      />

                      <ModelDetailsItem label="Body" value={action?.body} />

                      <div className="mb-6">
                        <Label text="Expected HTTP Status Code" />
                        {action?.expectedSuccessStatusCodes?.map(
                          (statusCode: string) => {
                            return (
                              <p className="text-sm bg-primary-stroke w-max px-4 py-1 rounded-full text-primary-mainText break-all mr-3">
                                {statusCode}
                              </p>
                            )
                          }
                        )}
                      </div>

                      <ModelDetailsItem
                        label="Error Message"
                        value={action?.errorMessage}
                      />

                      {/* Action Output */}
                      {action?.addActionOutput && (
                        <ActionOutputDetails outputs={action?.outputs} />
                      )}
                    </>
                  )}
                </>
              }
            />
          </div>
        )
      })}
    </div>
  )
}
