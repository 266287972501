import { FC } from 'react'

export const Status: FC<{ label: string; isPublished: boolean }> = ({
  label,
  isPublished,
}) => {
  return (
    <span
      className={`px-2 py-1 rounded-full font-medium bg-opacity-25 text-sm capitalize ${
        isPublished
          ? 'bg-green-100 text-green-500'
          : 'bg-orange-100 text-orange-500'
      }`}
    >
      {label}
    </span>
  )
}
