import { FC, ReactNode } from 'react'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { cx } from '../../utils'
import { Loader } from './Loader'

export enum FramePosition {
  left = '',
  center = 'mx-auto'
}

interface IUploadImageFrame {
  imageScr: string
  title: string
  id: string
  onRemoveImage: () => void
  onFileChange: any
  framePosition?: FramePosition
  loading?: boolean
}

interface IWrapper {
  loading?: boolean
  children: ReactNode
  dashedBorder?: boolean
}

export const UploadImageFrame: FC<IUploadImageFrame> = ({
  imageScr,
  title,
  id,
  onRemoveImage,
  onFileChange,
  framePosition = FramePosition.center,
  loading
}) => {
  const Wrapper: FC<IWrapper> = ({ loading, dashedBorder, children }) => {
    return (
      <div
        className={cx([
          'w-32 h-32 border border-primary-stroke rounded relative flex items-center',
          dashedBorder && 'border-dashed flex-col justify-center',
          framePosition
        ])}
      >
        {loading ? <Loader /> : children}
      </div>
    )
  }
  return (
    <>
      {!!imageScr && imageScr !== 'NoLogo' ? (
        <Wrapper loading={loading}>
          <img
            src={imageScr}
            alt="Logo"
            className="object-cover w-full h-auto max-h-full"
          />
          <label htmlFor=""></label>
          <button
            type="button"
            className="w-6 h-6 text-primary-secText hover:text-primary absolute top-1 right-1 bg-white rounded"
            title="Remove"
            onClick={onRemoveImage}
          >
            <span className="w-4 h-4 inline-block">
              <Icon name="Close" />
            </span>
          </button>
        </Wrapper>
      ) : (
        <Wrapper loading={loading} dashedBorder>
          <div className="text-primary-secText w-7 h-7">
            <Icon name="Image" size={30} />
          </div>
          <h4 className="text-sm text-primary-secText mt-4 mb-3">{title}</h4>
          <button
            type="button"
            className="cursor-pointer text-sm underline text-primary focus:outline-none hover:text-blue-mainText"
            title="Browse"
          >
            <label htmlFor={id} className="cursor-pointer">
              <input
                type="file"
                id={id}
                className="hidden"
                accept="image/*"
                onChange={onFileChange}
              />
              Browse here
            </label>
          </button>
        </Wrapper>
      )}
    </>
  )
}
