import { FC } from 'react'
import { IOutput } from '../../interface'

interface IActionOutputDetails {
  outputs: IOutput[]
}

export const ActionOutputDetails: FC<IActionOutputDetails> = ({ outputs }) => {
  return (
    <div className="mt-8 mb-2">
      <p className="text-primary-mainText mb-1.5">Action Output</p>
      <div className="flex w-full lg:w-3/5 gap-4 py-2 items-center border-primary-stroke pb-1">
        <p className="text-primary-secText font-medium w-1/2 text-sm">
          Property Name
        </p>
        <p className="text-primary-secText font-medium w-1/2 text-sm">Value</p>
      </div>
      {outputs?.map((output: IOutput, index: number) => (
        <div className="flex w-full lg:w-3/5 gap-4 py-2" key={index}>
          <div className="mb-6 w-1/2">
            <p className="text-sm text-primary-mainText break-all">
              {output?.propertyName}
            </p>
          </div>
          <div className="mb-6 w-1/2">
            <p className="text-sm text-primary-mainText break-all">
              {output?.value}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}
