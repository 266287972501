import { useEffect, useState } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch
} from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'
import {
  Accordion,
  BackButton,
  Checkbox,
  Container,
  FormControl,
  FormFooter,
  Input,
  InputSize,
  Label,
  Loader,
  Paragraph,
  ParagraphSize,
  SectionTitle
} from '../../../../components'
import {
  MODELS_PATH,
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS
} from '../../../../constants'
import { useFetch } from '../../../../hooks'
import { inputClasses } from '../../../../utils'
import { useModelDetailsContext } from '../../context/ModelDetailsContext'
import {
  expectedSuccessStatusCodesData,
  requestMethodTypes
} from '../../dummy-data'
import {
  ICreateOption,
  IExpectedStatusCode,
  IOption,
  IProperty,
  optionIState
} from '../../interface'
import { handleErrors } from '../../utils/handle-errors'
import { AddNewButton } from '../model-form-ui/AddNewButton'
import { RemoveButton } from '../model-form-ui/RemoveButton'
import { OptionApiAccordion } from './OptionApiAccordion'
import { OptionProperties } from './OptionProperties'

export const ModelOptions = () => {
  const navigate = useNavigate()
  let { modelId } = useParams()
  const { setModelId, model, loading } = useModelDetailsContext()

  const [properties, setProperties] = useState<IProperty[]>([])
  const [hasOptions, setHasOptions] = useState<boolean>(false)

  const { apiCall: createModelOption, loading: createLoading } = useFetch('put')
  const {
    apiCall: getModelOptions,
    loading: optionsLoading,
    response: optionsData
  } = useFetch('get')

  const methods = useForm({
    defaultValues: {
      options: [optionIState]
    }
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options'
  })

  const [options] = useWatch({
    control,
    name: ['options']
  })

  const addNewOption = () => {
    append(optionIState)
  }

  const removeOption = (index: number) => {
    remove(index)
  }

  const handleSubmitForm: SubmitHandler<any> = (data: ICreateOption) => {
    const options = data?.options?.map((option: IOption) => {
      const enabledStatusCodes =
        option?.enableAction?.expectedSuccessStatusCodes?.map(
          (statusCode: IExpectedStatusCode) => statusCode.value
        )
      const enableAction = {
        ...option?.enableAction,
        expectedSuccessStatusCodes: enabledStatusCodes,
        methodType: option?.enableAction?.methodType?.value
      }

      const disabledStatusCodes =
        option?.disableAction?.expectedSuccessStatusCodes?.map(
          (statusCode: IExpectedStatusCode) => statusCode.value
        )
      const disableAction = {
        ...option?.disableAction,
        expectedSuccessStatusCodes: disabledStatusCodes,
        methodType: option?.disableAction?.methodType?.value
      }

      return {
        propertyValues: option?.propertyValues,
        enableAction: option?.isExternalApi ? enableAction : null,
        disableAction: option?.isExternalApi ? disableAction : null
        // id: option?.id || 0
      }
    })

    const intModelId = modelId ? parseInt(modelId, 10) : 0

    const optionObject = {
      integrationStructureId: intModelId,
      options
    }

    createModelOption(
      `${USER_PROFILE_API}/integration-models/options`,
      optionObject,
      () => {
        showToast('success', 'Model option created successfully!')
        navigate(`${MODELS_PATH}/${modelId}/options`)
      },
      (err) => {
        handleErrors(err)
      },
      USER_PROFILE_API_HEADERS
    )
  }

  const transformDataOnEdit = () => {
    const options = optionsData?.data.map((option: IOption) => {
      let disableAction = {}
      let enableAction = {}
      if (option?.disableAction !== null) {
        const disableMethodType = {
          label: requestMethodTypes?.find(
            (method: any) => method.value === option?.disableAction?.methodType
          )?.label,
          value: option?.disableAction?.methodType
        }

        const disabledStatusCodes =
          option?.disableAction?.expectedSuccessStatusCodes?.map(
            (statusCode: any) => {
              return {
                label: expectedSuccessStatusCodesData?.find(
                  (status) => status.value === statusCode
                )?.label,
                value: statusCode
              }
            }
          )

        disableAction = {
          ...option.disableAction,
          methodType: disableMethodType,
          expectedSuccessStatusCodes: disabledStatusCodes,
          ...(option?.disableAction?.id && { id: option?.disableAction?.id })
        }

        const enableMethodType = {
          label: requestMethodTypes?.find(
            (method: any) => method.value === option?.enableAction?.methodType
          )?.label,
          value: option?.enableAction?.methodType
        }

        const enabledStatusCodes =
          option?.enableAction?.expectedSuccessStatusCodes?.map(
            (statusCode: any) => {
              return {
                label: expectedSuccessStatusCodesData?.find(
                  (status) => status.value === statusCode
                )?.label,
                value: statusCode
              }
            }
          )

        enableAction = {
          ...option.enableAction,
          methodType: enableMethodType,
          expectedSuccessStatusCodes: enabledStatusCodes,
          ...(option?.enableAction?.id && { id: option?.enableAction?.id })
        }
      }

      return {
        ...option,
        disableAction: option?.disableAction !== null ? disableAction : null,
        enableAction: option?.enableAction !== null ? enableAction : null,
        isExternalApi: option?.enableAction !== null
      }
    })
    const transformedData = {
      options
    }
    reset(transformedData)
  }

  useEffect(() => {
    if (!!modelId) {
      setModelId(modelId)
      getModelOptions(
        `${USER_PROFILE_API}/integration-models/options?integrationStructureId=${modelId}`,
        USER_PROFILE_API_HEADERS
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId])

  useEffect(() => {
    const properties = model?.body?.properties?.filter(
      (prop: IProperty) =>
        prop?.name !== 'user_id' && prop?.name !== model?.modelId
    )
    setProperties(properties)
  }, [model])

  useEffect(() => {
    setHasOptions(!!optionsData?.data?.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsData])

  useEffect(() => {
    if (hasOptions) {
      transformDataOnEdit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOptions])

  if (loading || optionsLoading) {
    return <Loader />
  }

  return (
    <Container>
      <div>
        <BackButton
          to={MODELS_PATH}
          className="mb-4"
          label="Back to Models"
          children={undefined}
        />
        <div className="mb-10">
          <SectionTitle noMargin className="flex">
            {hasOptions ? 'Edit Model Options' : 'Create Model Options'}
          </SectionTitle>
          <Paragraph parahraphSize={ParagraphSize.sm}>
            {hasOptions
              ? 'Edit model options to configure enabled or disabled API calls.'
              : 'Create model options to configure enabled or disabled API calls .'}
          </Paragraph>
        </div>
      </div>
      <form action="">
        <FormProvider {...methods}>
          {fields?.map((field, index) => {
            const option = options && options[index]
            const optionErrors = errors?.options?.[index]
            return (
              <div className="flex justify-between mb-6" key={field?.id}>
                <Accordion
                  title={`Option - ${index + 1}`}
                  opened={true}
                  className="flex-1"
                  accordionClassName="border-l-2 border-r-2 border-b-2"
                  noAnimation
                  content={
                    <>
                      <OptionProperties
                        properties={properties}
                        registerName={`options.${index}.propertyValues`}
                        errors={optionErrors}
                      />
                      <FormControl>
                        <Label
                          required
                          text="Model Action Type"
                          htmlFor="model-action-type"
                        />
                        <Input
                          id="model-action-type"
                          inputSize={InputSize.sm}
                          className={inputClasses}
                          value="Toggle"
                          disabled
                        />
                      </FormControl>
                      <div className="my-6">
                        <p className="text-primary-secText">API Calls</p>
                        <FormControl>
                          <Controller
                            control={control}
                            name={`options.${index}.isExternalApi`}
                            render={({ field: { onChange, value, ref } }) => (
                              <Checkbox
                                label="Enable External API (Optional)"
                                id={`externalApi-${index}`}
                                checked={value || false}
                                inputRef={ref}
                                onChange={onChange}
                                wrapperClassName="mt-3"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      {option?.isExternalApi && (
                        <>
                          <Accordion
                            title="API Call Enabled"
                            opened={true}
                            className="mb-4"
                            noAnimation
                            content={
                              <OptionApiAccordion
                                index={index}
                                arrayName={`options.${index}.enableAction`}
                                errors={optionErrors?.enableAction}
                              />
                            }
                          />
                          <Accordion
                            title="API Call Disabled"
                            opened={true}
                            className="flex-1"
                            noAnimation
                            content={
                              <OptionApiAccordion
                                index={index}
                                arrayName={`options.${index}.disableAction`}
                                errors={optionErrors?.disableAction}
                              />
                            }
                          />
                        </>
                      )}
                    </>
                  }
                />
                <RemoveButton
                  onClick={() => removeOption(index)}
                  className="ml-2 top-4"
                />
              </div>
            )
          })}
          <AddNewButton text="Add New Option" onClick={addNewOption} />
        </FormProvider>
        <FormFooter
          onSubmit={handleSubmit(handleSubmitForm)}
          loading={createLoading}
          saveBtnText={hasOptions ? 'Save' : 'Create'}
        />
      </form>
    </Container>
  )
}
