//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { BtnSize, BtnType, Button } from '../../../../components'
import { MODELS_CREATE_PATH } from '../../../../constants'
import { cx, primaryBtnClasses } from '../../../../utils'

interface ICreateModelButton {
  onCreateReadyUseTemplate?: () => void
}

export const CreateModelButton: FC<ICreateModelButton> = () => {
  const navigate = useNavigate()
  return (
    <div>
      <Button
        btnType={BtnType.primary}
        btnSize={BtnSize.normal}
        className={cx([
          primaryBtnClasses,
          'flex items-center whitespace-nowrap',
        ])}
        onClick={() => navigate(MODELS_CREATE_PATH)}
      >
        <span className="w-5 h-5 inline-block">
          <Icon name="Plus" size="20" />
        </span>
        <span className="ml-2 mr-2">New Model</span>
      </Button>
    </div>
  )
}
