import { useEffect } from 'react'

import { AuthProvider } from 'oidc-react'
import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from './components/errorBoundary'
import { oidcConfig, userManager } from './config/oidc-auth'
import { PermissionsProvider } from './context'
import { useToggle } from './hooks'
import ProtectedRouter from './router/ProtectedRouter'
import { Router as MainRouter } from './router/Router'
import { setPostLoginUri } from './utils'
// @ts-ignore
import { getUserData } from '@nodus/authentication'

const Root = () => {
  const { setVisible } = useToggle()
  const { access_token } = getUserData()

  const iFrames = document.getElementsByTagName('iframe')
  if (!!iFrames) {
    for (let index = 0; index < iFrames.length; index++) {
      const element = iFrames[index]
      element.style.display = 'none'
    }
  }
  useEffect(() => {
    setPostLoginUri()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !access_token && setVisible(true)
  }, [access_token]) // eslint-disable-line react-hooks/exhaustive-deps

  // const clearAllCookies = () => {
  //   return document.cookie.split(";").forEach(function (c) {
  //     document.cookie = c
  //       .replace(/^ +/, "")
  //       .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  //   });
  // };

  userManager.events.addSilentRenewError(() => {
    setVisible(true)
  })

  return (
    <>
      <ErrorBoundary>
        <AuthProvider {...oidcConfig}>
          <PermissionsProvider>
            <ProtectedRouter>
              <Routes>
                <Route path="*" element={<MainRouter />} />
              </Routes>
            </ProtectedRouter>
          </PermissionsProvider>
        </AuthProvider>
      </ErrorBoundary>
    </>
  )
}

export default Root
