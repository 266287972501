import { FC, useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'
import { Loader } from '../../components'
import { BASE_URL, UNIVERSAL_LOGIN_PATH } from '../../constants'
import { useFetch } from '../../hooks'
import { IBrandingDetails } from '../../interfaces'
import { SEO, linkLikeBtnClasses } from '../../utils'
import { BrandingForm } from './components'

export const Branding: FC = () => {
  let { id } = useParams()
  const navigate = useNavigate()
  const [hasError, setHasError] = useState<boolean>(false)

  const { apiCall: getBrand, loading, response: branding } = useFetch('get')
  const { apiCall: updateBrandingRequest, loading: updateLoading } =
    useFetch('post')

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = useForm()

  useEffect(() => {
    SEO({
      title: 'Porta - Branding Details'
    })

    getBrand(
      `${BASE_URL}/BrandingConfigurations/Edit?id=${id}`,
      {},
      (response) => {
        // setBranding(res);
        reset(response)
      },
      () => {
        setHasError(true)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateBranding: SubmitHandler<any> = (data: IBrandingDetails) => {
    const brandingObj = {
      ...data,
      name: data.name.trim()
    }
    updateBrandingRequest(
      `${BASE_URL}/BrandingConfigurations/Edit`,
      brandingObj,
      () => {
        navigate(UNIVERSAL_LOGIN_PATH)
        showToast('success', 'Costumization updated successfully!')
      },
      (error) => {
        showToast('error', error?.response?.data)
      }
    )
  }

  if (loading) {
    return <Loader />
  }

  if (hasError) {
    return (
      <div>
        <p className="mb-4">
          {' '}
          Costumization with this <strong>{id}</strong> does not exist!
        </p>

        <a href={UNIVERSAL_LOGIN_PATH} className={linkLikeBtnClasses}>
          Back to branding
        </a>
      </div>
    )
  }

  return (
    <BrandingForm
      onSubmit={handleSubmit(updateBranding)}
      errors={errors}
      register={register}
      control={control}
      watch={watch}
      setValue={setValue}
      loading={loading}
      updateLoading={updateLoading}
      branding={branding}
      isDirty={isDirty}
      editMode
    />
  )
}
