import { FC, useEffect, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import Select from 'react-select'

import { Input, InputSize, Label, Message } from '../../../../components'
import { cx, inputClasses, reactSelectStyle } from '../../../../utils'
import { IProperty, outputIState } from '../../interface'

interface IActionOutputs {
  index: number
  properties: IProperty[]
}

export const ActionOutputs: FC<IActionOutputs> = ({ index, properties }) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext()

  const { fields, replace } = useFieldArray({
    control,
    name: `actions[${index}].outputs`
  })

  const [actionOutputProperties, setActionOutputProperties] = useState<any>()

  useEffect(() => {
    const filteredProps = properties?.filter((prop) => prop.name !== 'user_id')
    setActionOutputProperties(filteredProps)
  }, [properties])

  useEffect(() => {
    fields?.length < 1 && replace(outputIState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mt-8">
      {fields?.map((field, k) => {
        return (
          <div key={field.id} className="flex gap-4 mb-3">
            <div className="w-1/2">
              <Label text="Property Name" />
              <Controller
                control={control}
                name={`actions[${index}].outputs[${k}].property`}
                rules={{
                  required: true
                }}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    noOptionsMessage={() => 'No property found.'}
                    options={actionOutputProperties || []}
                    getOptionLabel={(x) => x?.name}
                    getOptionValue={(x) => x?.id}
                    isSearchable
                    ref={ref}
                    value={!!value?.name ? value : null}
                    menuPosition="fixed"
                    menuPortalTarget={
                      document?.querySelector(
                        '.porta-app__container'
                      ) as HTMLElement
                    }
                    onChange={onChange}
                    className={cx([
                      'text-sm',
                      (errors as any).actions?.[index]?.outputs?.[k]
                        ?.property && 'invalid-field'
                    ])}
                    classNamePrefix="porta-react-select"
                    placeholder="Select Property"
                    styles={reactSelectStyle}
                  />
                )}
              />

              {(errors as any).actions?.[index]?.outputs?.[k]?.property && (
                <Message message="Property is required" />
              )}
            </div>

            <div className="w-1/2">
              <Label text="Value" />
              <Input
                id="newValue"
                {...register(`actions[${index}].outputs[${k}].value`, {
                  required: 'New Value is required'
                })}
                error={
                  !!(errors as any).actions?.[index]?.headers?.[k]?.value &&
                  (errors as any).actions?.[index]?.headers?.[k]?.value?.message
                }
                inputSize={InputSize.sm}
                className={inputClasses}
                placeholder="Enter New Value"
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
