import { FC, useEffect, useState } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form'
import Select from 'react-select'

import {
  Checkbox,
  Input,
  InputSize,
  Message,
  MessageTypes
} from '../../../../components'
import { Nested } from '../../../../components/Icons'
import { cx, inputClasses, reactSelectStyle } from '../../../../utils'
import {
  IInputType,
  IProperty,
  nestedPropertyIState,
  propertyIState
} from '../../interface'
import { ConstTypes, useConstant } from '../../utils/useConstant'
import { AddNewButton } from '../model-form-ui/AddNewButton'
import { DefaultProperties } from '../model-form-ui/DefaultProperties'
import { RemoveButton } from '../model-form-ui/RemoveButton'
import { ChildProperties } from './ChildProperties'
import { MultiOptionDefaultProperties } from './MultiOptionDefaultProperties'

interface IModelProperties {
  propertiesData?: IProperty[]
  isFromEdit?: boolean
  isMultiOption?: boolean
}

export const ModelProperties: FC<IModelProperties> = ({
  isFromEdit,
  propertiesData,
  isMultiOption
}) => {
  const { constTypes: inputTypes } = useConstant(ConstTypes.inputTypes)
  const { constTypes: designationTypes } = useConstant(
    ConstTypes.designationTypes
  )

  const multiOptionInputTypes = inputTypes.filter(
    (el) => el.id !== 6 && el.id !== 7
  )

  const [filteredInputTypes, setFilteredInputTypes] = useState(inputTypes)

  const {
    register,
    control,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext()

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'properties'
  })

  const [modelType, properties] = useWatch({
    name: ['modelType', 'properties']
  })

  useEffect(() => {
    isFromEdit && replace(propertiesData || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replace, propertiesData])

  useEffect(() => {
    if (modelType === 'multioption' || (isFromEdit && isMultiOption))
      setFilteredInputTypes(multiOptionInputTypes)
    else inputTypes && setFilteredInputTypes(inputTypes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelType, inputTypes])

  useEffect(() => {
    if (modelType === 'multioption') {
      const filteredProperties = properties?.filter(
        (property: IProperty) =>
          !isFieldTypeObject(property) &&
          property?.designationType?.name !== 'Title' &&
          property?.designationType?.name !== 'Description'
      )
      replace(filteredProperties)
      clearErrors()
    } else if (modelType === 'standard' && properties?.length === 1) {
      append(propertyIState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelType])

  const addNewProperty = () => {
    append(propertyIState)
  }

  const removeProperty = (index: number) => {
    remove(index)
  }

  const onDataTypeChange = (selectedOption: IInputType, index: number) => {
    if (selectedOption?.id === 6 || selectedOption?.id === 7) {
      if (properties[index]?.nested?.length < 1)
        setValue(`properties[${index}].nested`, [nestedPropertyIState])
    } else setValue(`properties[${index}].nested`, [])
  }

  const isFieldTypeObject = (property: IProperty) => {
    return (
      properties &&
      (property?.inputType?.id === 6 || property?.inputType?.id === 7)
    )
  }

  const propertiesLength = propertiesData?.length || 0

  const removePropertyButton = (condition: boolean, index: number) => {
    if (condition) return <RemoveButton onClick={() => removeProperty(index)} />
    return <div className="w-6 h-6"></div>
  }

  const filteredDesignationsTypes = designationTypes?.filter(
    (designationType) => {
      return !properties?.find((property: IProperty) => {
        if (modelType === 'multioption') {
          return (
            property?.designationType?.name === designationType?.name ||
            designationType?.name === 'Title' ||
            designationType?.name === 'Description'
          )
        }
        return property?.designationType?.name === designationType?.name
      })
    }
  )
  const propertiesName = properties?.map((property: IProperty) => property.name)

  return (
    <>
      <p className="mt-9 pb-3 border-b border-primary-stroke text-primary-secText text-m">
        Model Properties
      </p>
      <div className="flex items-center border-b border-primary-stroke justify-between mt-6 pb-2 gap-4 ml-4">
        <div className="text-primary-secText font-medium flex-1 text-sm flex-shrink-0">
          Property Name
        </div>
        <div className="text-primary-secText font-medium max-w-xs flex-1 text-sm min-w-0">
          Data Type
        </div>
        <div className="text-primary-secText font-medium max-w-xs flex-1 text-sm min-w-0">
          Designation Type
        </div>
        <div className="text-primary-secText font-medium max-w-xs w-14 text-sm text-center flex-shrink-0">
          Required
        </div>
        <div className="w-6 flex-shrink-0"></div>
      </div>

      <div className="mt-4">
        {!isFromEdit && <DefaultProperties />}
        {modelType === 'multioption' && !isFromEdit && (
          <MultiOptionDefaultProperties />
        )}
        {fields?.map((field, index) => {
          const property = properties && properties[index]
          // @ts-ignore
          const propertyErrors = errors?.properties?.[index]
          if (index === 0 && !isFromEdit) {
            properties && setValue('modelId', property?.name)
          }
          return (
            <div key={field.id}>
              <div className="flex justify-between py-2 px-1 mb-3 hover:bg-gray-50 transition rounded gap-4 ml-4 relative">
                <div className="flex-1">
                  {isFieldTypeObject(property) && (
                    <div className="absolute top-1/2 -left-4">
                      <Nested />
                    </div>
                  )}
                  <Input
                    {...register(`properties.${index}.name`, {
                      required: 'This field is required',
                      validate: {
                        notLowerCase: (value: string) =>
                          /^[a-z_]+$/.test(value) ||
                          "Property must be lowercase, can't contain spaces, numbers or special characters",
                        notValidPropertyName: (value: string) =>
                          propertiesName.indexOf(value) ===
                            propertiesName.lastIndexOf(value) ||
                          'Property Name needs to be unique'
                      }
                    })}
                    inputSize={InputSize.sm}
                    className={inputClasses}
                    type="text"
                    disabled={isFromEdit && index + 1 <= propertiesLength}
                    maxLength={300}
                    placeholder={
                      index === 0 ? 'Enter Model Id' : 'Enter Property Name'
                    }
                    error={
                      !!propertyErrors?.name && propertyErrors?.name?.message
                    }
                  />
                </div>

                <div className="flex-1 max-w-xs">
                  <Controller
                    control={control}
                    name={`properties.${index}.inputType`}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, ref, value } }) => (
                      <Select
                        options={filteredInputTypes || []}
                        getOptionLabel={(x) => x?.name}
                        getOptionValue={(x) => x?.id}
                        isDisabled={
                          (isFromEdit && index + 1 <= propertiesLength) ||
                          index === 0
                        }
                        isSearchable
                        ref={ref}
                        value={!!value?.name ? value : null}
                        menuPosition="fixed"
                        onChange={(selectedOption) => {
                          onChange(selectedOption)
                          onDataTypeChange(selectedOption, index)
                        }}
                        className={cx([
                          'text-sm',
                          propertyErrors?.inputType && 'invalid-field'
                        ])}
                        classNamePrefix="porta-react-select"
                        placeholder="Select Data Type"
                        styles={reactSelectStyle}
                      />
                    )}
                  />

                  {propertyErrors?.inputType && (
                    <Message
                      containerClassName="mt-2"
                      type={MessageTypes.error}
                      message="Data Type is required."
                    />
                  )}
                </div>

                <div className="flex-1 max-w-xs">
                  {!isFieldTypeObject(property) && (
                    <Controller
                      control={control}
                      shouldUnregister
                      name={`properties.${index}.designationType`}
                      render={({ field: { onChange, ref, value } }) => (
                        <Select
                          options={filteredDesignationsTypes || []}
                          getOptionLabel={(x) => x?.name}
                          getOptionValue={(x) => x?.id}
                          isDisabled={
                            (isFromEdit && index + 1 <= propertiesLength) ||
                            index === 0
                          }
                          isSearchable
                          ref={ref}
                          value={!!value?.name ? value : null}
                          menuPosition="fixed"
                          // portalPlacement="auto"
                          onChange={onChange}
                          className={cx(['text-sm'])}
                          classNamePrefix="porta-react-select"
                          placeholder="Select Designation"
                          styles={reactSelectStyle}
                        />
                      )}
                    />
                  )}
                </div>

                {!isFromEdit && (
                  <div className="w-14 text-center">
                    <Controller
                      control={control}
                      name={`properties.${index}.isRequired`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Checkbox
                          label=""
                          id={`required-${index}`}
                          disabled={
                            (isFromEdit && index + 1 <= propertiesLength) ||
                            index === 0
                          }
                          checked={value || false}
                          inputRef={ref}
                          onChange={onChange}
                          wrapperClassName="mt-3"
                        />
                      )}
                    />
                  </div>
                )}

                {isFromEdit && (
                  <>
                    {index + 1 <= propertiesLength ? (
                      <div className="w-14 text-center">
                        <Controller
                          control={control}
                          name={`properties.${index}.isRequired`}
                          render={({ field: { onChange, value, ref } }) => (
                            <Checkbox
                              label=""
                              id={`required-${index}`}
                              disabled={
                                (isFromEdit && index + 1 <= propertiesLength) ||
                                index === 0
                              }
                              checked={value || false}
                              inputRef={ref}
                              onChange={onChange}
                              wrapperClassName="mt-3"
                            />
                          )}
                        />
                      </div>
                    ) : (
                      <div className="w-14"></div>
                    )}
                  </>
                )}

                {isFromEdit
                  ? removePropertyButton(
                      !(index + 1 <= propertiesLength),
                      index
                    )
                  : removePropertyButton(index + 1 > 2, index)}
              </div>
              {isFieldTypeObject(property) && (
                <ChildProperties
                  nestedIndex={index}
                  inputTypes={multiOptionInputTypes}
                  childProperties={propertiesData?.[index]?.childProperties}
                  isFromEdit={isFromEdit || false}
                />
              )}
            </div>
          )
        })}

        <div className="ml-4">
          <AddNewButton text="Add Property" onClick={addNewProperty} />
        </div>
      </div>
    </>
  )
}
