import { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Input, InputSize, Label } from '../../../../components'
import { inputClasses } from '../../../../utils'
import { headersIState } from '../../interface'
import { AddNewButton } from '../model-form-ui/AddNewButton'
import { RemoveButton } from '../model-form-ui/RemoveButton'

export const DataImportHeaders: FC = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers',
  })
  const addNewRequestHeader = () => {
    append(headersIState)
  }

  const removeRequestHeader = (index: number) => {
    remove(index)
  }
  return (
    <>
      <div className="mb-6">
        <Label text="Request Headers (Optional)" />
        <div>
          <div className="flex gap-4 py-2 px-1 mb-3 items-center border-b border-primary-stroke justify-between pb-2">
            <div className="text-primary-secText font-medium w-1/2 text-sm">
              Header Name
            </div>
            <div className="text-primary-secText font-medium w-1/2 text-sm">
              Header Value
            </div>
            {fields.length !== 1 && <div className="w-6"></div>}
          </div>

          {fields?.map((field, index) => {
            return (
              <div
                key={field.id}
                className="flex gap-4 py-2 px-1 mb-3 hover:bg-gray-50 transition rounded"
              >
                <div className="w-1/2">
                  <Input
                    {...register(`headers[${index}].key`, {
                      required: 'Header name should no be empty',
                    })}
                    error={
                      !!(errors as any).headers?.[index]?.key &&
                      (errors as any).headers?.[index]?.key?.message
                    }
                    inputSize={InputSize.sm}
                    className={inputClasses}
                    type="text"
                    maxLength={300}
                    placeholder="Header Name"
                  />
                </div>

                <div className="w-1/2">
                  <Input
                    {...register(`headers[${index}].value`, {
                      required: 'Header value should no be empty',
                    })}
                    error={
                      !!(errors as any).headers?.[index]?.value &&
                      (errors as any).headers?.[index]?.value?.message
                    }
                    inputSize={InputSize.sm}
                    className={inputClasses}
                    type="text"
                    placeholder="Header Value"
                  />
                </div>
                <RemoveButton onClick={() => removeRequestHeader(index)} />
              </div>
            )
          })}

          {fields?.length < 3 && (
            <AddNewButton
              text="Add Request Header"
              onClick={addNewRequestHeader}
            />
          )}
        </div>
      </div>
    </>
  )
}
