import { FC, useEffect } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'

import { BASE_URL, UNIVERSAL_LOGIN_PATH } from '../../constants'
import { useFetch } from '../../hooks'
import { IBranding } from '../../interfaces'
import { SEO, brandingConfigItemIState } from '../../utils'
import { BrandingForm } from './components'

export const BrandingCreate: FC = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm()

  const { apiCall: createBranding, loading } = useFetch('post')

  useEffect(() => {
    SEO({
      title: 'Porta - Branding Create'
    })

    reset(brandingConfigItemIState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCreateBranding: SubmitHandler<any> = (data: IBranding) => {
    const brandingObj = {
      ...data,
      name: data.name.trim()
    }
    createBranding(
      `${BASE_URL}/BrandingConfigurations/CreateBrandingConfiguration`,
      brandingObj,
      () => {
        showToast('success', 'Costumization added successfully!')
        navigate(UNIVERSAL_LOGIN_PATH)
      },
      (error) => {
        showToast('error', error?.response?.data)
      }
    )
  }

  return (
    <BrandingForm
      onSubmit={handleSubmit(onCreateBranding)}
      errors={errors}
      register={register}
      control={control}
      setValue={setValue}
      watch={watch}
      loading={loading}
      isCreate
    />
  )
}
