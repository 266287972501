import { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Input, InputSize } from '../../../../components'
import { inputClasses } from '../../../../utils'
import { headersIState } from '../../interface'
import { AddNewButton } from '../model-form-ui/AddNewButton'
import { RemoveButton } from '../model-form-ui/RemoveButton'

interface IRequestHeaders {
  index: number
  arrayName?: string
}

export const RequestHeaders: FC<IRequestHeaders> = ({
  index,
  arrayName = `actions[${index}.headers]`
}) => {
  const { register, control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: arrayName
  })

  const addNewRequestHeader = () => {
    append(headersIState)
  }

  const removeRequestHeader = (index: number) => {
    remove(index)
  }

  return (
    <>
      <p className="text-primary-secText mb-1">Configure request headers</p>
      <div className="flex gap-4 py-2 px-1 mb-3 items-center border-b border-primary-stroke justify-between pb-2">
        <div className="text-primary-secText font-medium w-1/2 text-sm">
          Header Name
        </div>
        <div className="text-primary-secText font-medium w-1/2 text-sm">
          Header Value
        </div>
        <div className="w-6"></div>
      </div>

      {fields?.map((field, k) => {
        return (
          <div
            key={field.id}
            className="flex gap-4 py-2 px-1 mb-3 hover:bg-gray-50 transition rounded"
          >
            <div className="w-1/2">
              <Input
                {...register(`${arrayName}[${k}].key`)}
                inputSize={InputSize.sm}
                className={inputClasses}
                type="text"
                maxLength={300}
                placeholder="Header Name"
              />
            </div>

            <div className="w-1/2">
              <Input
                {...register(`${arrayName}[${k}].value`)}
                inputSize={InputSize.sm}
                className={inputClasses}
                type="text"
                placeholder="Header Value"
              />
            </div>

            <RemoveButton onClick={() => removeRequestHeader(k)} />
          </div>
        )
      })}

      <AddNewButton text="Add Request Header" onClick={addNewRequestHeader} />
    </>
  )
}
