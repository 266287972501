import Select from 'react-select';
import { Checkbox, Input, InputSize } from '../../../../components';
import { inputClasses, reactSelectStyle } from "../../../../utils";
import { ConstTypes, useConstant } from "../../utils/useConstant";

export const DefaultProperties = () => {
  const { constTypes: defaultProperties } = useConstant(
    ConstTypes.defaultProperties
  );

  const dataType = {name: "String", id: '1'}

  return (
    <>
      {defaultProperties?.map((property) => {
        return (
          <div
            className="flex justify-between py-2 px-1 mb-3 hover:bg-gray-50 transition rounded gap-4 ml-4"
            key={property?.id}
          >
            <div className="flex-1 flex-shrink-0">
              <Input
                inputSize={InputSize.sm}
                className={inputClasses}
                type="text"
                disabled
                maxLength={300}
                value={property.name}
              />
            </div>
            <div className="flex-1 max-w-xs min-w-0">
              <Select
                options={[]}
                value={dataType}
                getOptionLabel={(x) => x?.name}
                getOptionValue={(x) => x?.id}
                isDisabled={true}
                menuPosition="fixed"
                className="text-sm"
                classNamePrefix="porta-react-select"
                styles={reactSelectStyle}
              />
            </div>
            <div className="flex-1 max-w-xs min-w-0">
              <Select
                options={[]}
                isDisabled={true}
                menuPosition="fixed"
                className="text-sm"
                classNamePrefix="porta-react-select"
                placeholder="Select Designation"
                styles={reactSelectStyle}
              />
            </div>
            <div className="w-14 text-center flex-shrink-0">
              <Checkbox
                label=""
                id="required"
                disabled
                checked={property?.isRequired}
                wrapperClassName="mt-3"
              />
            </div>
            <div className="w-6 flex-shrink-0"></div>
          </div>
        );
      })}
    </>
  );
};
