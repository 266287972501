export interface IProperty {
  name: string;
  id: string;
  inputTypeId?: number;
  inputType: {
    name: string;
    id: number
  };
  designationType:  {
    name: string;
    id: number
  };
  nested?: any;
  childProperties?: any;
  isRequired: boolean;
  isDefault?: boolean;
}

export interface IModel {
id?: number | undefined | string,
modelType: string,
name: string,
alias: string,
createdDate: string,
isPublished: boolean,
isDefault: boolean,
order: number,
}

export interface IHeaders {
key: string,
value: string
}

export interface IVisibilitySettings {
propertyName: string,
operatorId: number,
operator: {
  id: number;
  name: string;
}
property: {
  id: number;
  name: string;
}
propertyId?: string;
value: string,
}

export interface IOutput  {
property: {
  id: number;
  name: string;
};
propertyId?: string;
propertyName: string,
value: string,
}

export interface IActionTypeId {
name: string,
id: number
}

export interface IMethodType {
label: string,
value: string
}

export interface IInputType {
id: number,
name: string
}

export interface IExpectedStatusCode {
label: string,
value: number
}

export interface IResponseType {
label: string,
value: string
}

export interface IScheduleType {
label: string,
value: number
}

export interface IAction {
id?: string;
name: string,
methodType: IMethodType,
url: string,
headers: IHeaders[],
visibilitySettings: IVisibilitySettings[],
outputs: IOutput[],
body: string,
actionType: IActionTypeId,
actionTypeId: number;
redirectInNewTab: false,
showVisibilitySettings: false,
addActionOutput: false,
errorMessage: string,
expectedSuccessStatusCodes: IExpectedStatusCode[],
}

export interface ICreateModel {
modelId: string,
name: string,
icon: string,
image: string,
description: string,
alias: string,
modelType: string,
actions: IAction[],
properties: IProperty[]
multiOptionProperties: IProperty[]
}

export interface IOptionApi {
id?: string,
url: string,
body: string,
headers: IHeaders[],
errorMessage: string,
methodType: IMethodType,
expectedSuccessStatusCodes: IExpectedStatusCode[],
}

export interface IOption {
isExternalApi: boolean,
disableAction: IOptionApi,
enableAction: IOptionApi,
propertyValues: any,
id?: string
}

export interface ICreateOption {
integrationStructureId: number,
options: IOption[]
}

export interface IPipelineJob {
id: number,
getDataEndpoint: string
responseType: IResponseType,
propertyAccessor: string,
headers: IHeaders[],
scheduleId: IScheduleType,
scheduleFrequency: IScheduleType,
}

export type templateTypes = "subscription" | "newsletter" | "comments" | "none";

// Initial States
export const nestedPropertyIState = {
name: '',
inputType: null,
isRequired: false,
}

export const propertyIState = {
name: "",
inputType: null,
isRequired: false,
nested: []
};


export const propertiesIState = [
{
  id: 0,
  name: "",
  inputType: {
    id: 2,
    name: "String",
  },
  inputTypeId: 2,
  isRequired: true,
  nested: []
},
{
  id: 1,
  name: "",
  inputType: null,
  inputTypeId: 0,
  isRequired: false,
  nested: []
}
]

export const multiOptionPropertiesIState = [
{
  id: 2,
  name: "title",
  inputType: {
    id: 2,
    name: "String",
  },
  inputTypeId: 2,
  designationType: {
    id: 1,
    name: 'Title'
  },
  isRequired: true,
},
{
  id: 3,
  name: "description",
  inputType: {
    id: 2,
    name: "String",
  },
  inputTypeId: 2,
  designationType: {
    id: 3,
    name: 'Description'
  },
  isRequired: false
}
]

export const headersIState = {
key: "",
value: ""
}

export const visibilitySettingsIState =  {
propertyName: "",
operatorId: 1,
value: "",
property: null,
operator: null
}

export const outputIState =  {
propertyName: "",
value: "",
property: null
}

export const apiCallIState = {
url: "",
headers: [headersIState],
methodType: "",
body: "",
expectedSuccessStatusCodes: [],
errorMessage: "",
}

export const actionIState = {
name: "",
methodType: "",
url: "",
headers: [headersIState],
visibilitySettings: [
  visibilitySettingsIState
],
outputs: [outputIState],
body: "",
actionType: null,
redirectInNewTab: false,
showVisibilitySettings: false,
addActionOutput: false,
errorMessage: "",
expectedSuccessStatusCodes: []
}

export const optionIState = {
actionType: 'Toggle',
isExternalApi: false,
enableAction: apiCallIState,
disableAction: apiCallIState,
propertyValues: {}
}

export const optionsDataDummy = []
