import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalSize } from '../../../../components'
import { Bell, ChatMiddle, FileList } from '../../../../components/Icons'
import { MODELS_CREATE_PATH } from '../../../../constants'
import { templateTypes } from '../../interface'
import { TemplateTypeItem } from './TemplateTypeItem'

interface IUseModelTemplatesModal {
  isVisible: boolean
  toggle: () => void
}

export const UseModelTemplatesModal: FC<IUseModelTemplatesModal> = ({
  isVisible,
  toggle,
}) => {
  const navigate = useNavigate()
  const [template, setTemplate] = useState<templateTypes>('none')

  const closeModal = () => {
    setTemplate('none')
    toggle()
  }

  return (
    <Modal
      visible={isVisible}
      modalSize={ModalSize.md}
      hide={toggle}
      title="Choose segment template"
      onConfirmClick={() =>
        navigate(`${MODELS_CREATE_PATH}?template=${template}`)
      }
      onCloseClick={closeModal}
      onCancel={closeModal}
      confirmBtnText="Create"
      withFooter
      blockOutsideClick
      disabled={template === 'none'}
      //   loading={deleteTableItemLoading}
    >
      <div className="flex items-center justify-between gap-4">
        <TemplateTypeItem
          onClick={() => setTemplate('subscription')}
          activeTemplate={template}
          templateName="subscription"
          icon={<Bell width="36" />}
        />

        <TemplateTypeItem
          onClick={() => setTemplate('newsletter')}
          activeTemplate={template}
          templateName="newsletter"
          icon={<FileList width="36" />}
        />

        <TemplateTypeItem
          onClick={() => setTemplate('comments')}
          activeTemplate={template}
          templateName="comments"
          icon={<ChatMiddle width="36" />}
        />
      </div>
    </Modal>
  )
}
