import { FC } from 'react'
import { IVisibilitySettings } from '../../interface'
import { ModelDetailsItem } from './ModelDetailsItem'

interface IVisibilitySettingsDetails {
  visibilitySettings: IVisibilitySettings[]
}

export const VisibilitySettingsDetails: FC<IVisibilitySettingsDetails> = ({
  visibilitySettings
}) => {
  return (
    <div className="mb-4">
      <p className="text-primary-mainText mb-3">Visibility Settings</p>
      {visibilitySettings?.map((property: any) => {
        return (
          <div
            className="grid gap-4 grid-cols-3 w-full lg:w-4/5"
            key={property.id}
          >
            <ModelDetailsItem
              label="Property Name"
              value={property?.propertyName}
            />

            <ModelDetailsItem
              label="Operator"
              value={property?.operator?.name}
            />

            <ModelDetailsItem label="Value" value={property?.value} />
          </div>
        )
      })}
    </div>
  )
}
