import { FC } from 'react'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'

interface IAddNewButton {
  text: string
  onClick: () => void
}

export const AddNewButton: FC<IAddNewButton> = ({ text, onClick }) => {
  return (
    <button
      className="flex items-center text-sm text-primary hover:underline mt-4"
      onClick={onClick}
      type="button"
    >
      <Icon name="Plus" size={16} />
      {text}
    </button>
  )
}
