import { FC, ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Permission, SystemRoles, onlyForRoles } from '../context'
import { Page401, Page404 } from '../pages'
import { applicationsRoutes } from './Routes'

export const Router: FC = (): ReactElement => {
  const notAuthorized = !onlyForRoles(SystemRoles)

  const { appsEditor } = Permission
  const userHasOnlyAppsEditor = onlyForRoles([appsEditor])

  if (notAuthorized) {
    return <Page401 />
  }

  return (
    <section className="porta-content__container">
      <Routes>
        {applicationsRoutes.map((route, i) => {
          return (
            <Route
              path={route.path}
              element={route.element}
              key={i}
              index={route?.index}
            />
          )
        })}

        {userHasOnlyAppsEditor && <Route path="*" element={<Page401 />} />}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </section>
  )
}
