import { FC, useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import Select from 'react-select';

import {
  Checkbox,
  Input,
  InputSize
} from '../../../../components';
import { inputClasses, reactSelectStyle } from '../../../../utils';
import { IProperty, multiOptionPropertiesIState } from '../../interface';

interface IMultiOptionDefaultProperties {
  propertiesData?: IProperty[];
  isFromEdit?: boolean;
}

export const MultiOptionDefaultProperties: FC<
  IMultiOptionDefaultProperties
> = ({ propertiesData, isFromEdit }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, replace } = useFieldArray({
    control,
    name: 'multiOptionProperties',
  });

  const [multiOptionProperties, modelType, properties] = useWatch({
    name: ['multiOptionProperties', 'modelType', 'properties'],
  });

  useEffect(() => {
    modelType === 'multioption' && replace(multiOptionPropertiesIState)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelType])

  const propertiesName = multiOptionProperties?.concat(properties)?.map(
    (property: IProperty) => property.name
  );

  return (
    <div className="mt-4">
      {fields?.map((field, index) => {
        return (
          <div
            className="flex justify-between py-2 px-1 mb-3 ml-4 hover:bg-gray-50 transition rounded gap-4"
            key={field.id}
          >
            <div className="flex-1">
              <Input
                {...register(`multiOptionProperties.${index}.name`, {
                  required: 'This field is required',
                  validate: {
                    notLowerCase: (value: string) =>
                      /^[a-z_]+$/.test(value) ||
                      "Property must be lowercase, can't contain spaces, numbers or special characters",
                    notValidPropertyName: (value: string) =>
                      propertiesName.indexOf(value) ===
                        propertiesName.lastIndexOf(value) ||
                      'Property Name needs to be unique',
                  },
                })}
                inputSize={InputSize.sm}
                className={inputClasses}
                type="text"
                disabled={isFromEdit}
                maxLength={300}
                placeholder="Enter Property Name"
                error={
                  // @ts-ignore
                  !!errors.multiOptionProperties?.[index]?.name && errors.multiOptionProperties?.[index]?.name?.message
                }
              />
            </div>

            <div className="flex-1 max-w-xs">
              <Controller
                control={control}
                name={`multiOptionProperties.${index}.inputType`}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    options={[]}
                    getOptionLabel={(x) => x?.name}
                    getOptionValue={(x) => x?.id}
                    isDisabled={true}
                    ref={ref}
                    value={!!value?.name ? value : null}
                    menuPosition="fixed"
                    // portalPlacement="auto"
                    onChange={onChange}
                    className="text-sm"
                    classNamePrefix="porta-react-select"
                    styles={reactSelectStyle}
                  />
                )}
              />
            </div>

            <div className="flex-1 max-w-xs">
              <Controller
                control={control}
                name={`multiOptionProperties.${index}.designationType`}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    options={[]}
                    getOptionLabel={(x) => x?.name}
                    getOptionValue={(x) => x?.id}
                    isDisabled={true}
                    ref={ref}
                    value={!!value?.name ? value : null}
                    menuPosition="fixed"
                    onChange={onChange}
                    className="text-sm"
                    classNamePrefix="porta-react-select"
                    styles={reactSelectStyle}
                  />
                )}
              />
            </div>

            <div className="w-14 text-center">
              <Controller
                control={control}
                name={`multiOptionProperties.${index}.isRequired`}
                render={({ field: { onChange, value, ref } }: any) => (
                  <Checkbox
                    label=""
                    id={`multioption-required-${index}`}
                    disabled={true}
                    checked={value || false}
                    inputRef={ref}
                    onChange={onChange}
                    wrapperClassName="mt-3"
                  />
                )}
              />
            </div>
            <div className="w-6 flex-shrink-0"></div>
          </div>
        );
      })}
    </div>
  );
};
