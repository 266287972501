import { FC, useState } from 'react'
import { DropdownOption } from '../../../components'
import { ArrowRotateLeft } from '../../../components/Icons'
import { Dropdown } from '../../../components'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'

interface IResetDropdown {
  onDefaultReset: any
  onPreviousReset: any
}

export const ResetDropdown: FC<IResetDropdown> = ({
  onDefaultReset,
  onPreviousReset
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleDefaultReset = () => {
    onDefaultReset()
    setIsVisible(false)
  }

  const handlePreviousReset = () => {
    onPreviousReset()
    setIsVisible(false)
  }

  return (
    <Dropdown
      width="w-40"
      noPadding
      dropdownClassName={!isVisible ? 'hidden' : ''}
      dropdownContent={
        <>
          <DropdownOption
            label="Reset to Default"
            onClick={handleDefaultReset}
          />
          <DropdownOption
            label="Reset to Published"
            onClick={handlePreviousReset}
          />
        </>
      }
    >
      <div
        className="flex items-center whitespace-nowrap hover:bg-gray-200 focus:bg-gray-300 bg-gray-100 rounded text-sm cursor-pointer border p-2"
        onClick={() => setIsVisible(true)}
      >
        <ArrowRotateLeft />
        <span className="ml-2 mr-2 font-medium">Reset</span>
        <span className="mr-1.5 ">|</span>
        <span className="w-5 h-5 inline-block">
          <Icon name="ArrowDown" size="20" />
        </span>
      </div>
    </Dropdown>
  )
}
