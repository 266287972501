import { FC, ReactNode } from 'react'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { templateTypes } from '../../interface'

interface ITemplateTypeItem {
  onClick: () => void
  activeTemplate: templateTypes
  templateName: string
  icon: ReactNode
}

export const TemplateTypeItem: FC<ITemplateTypeItem> = ({
  onClick,
  activeTemplate,
  templateName,
  icon,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${
        activeTemplate === templateName
          ? 'border-primary hover:border-primary'
          : 'border-transparent hover:border-gray-300 '
      } bg-primary-bg relative min-w-0 border-2 rounded p-9 flex-col flex flex-1 items-center justify-center h-40 cursor-pointer transition`}
    >
      <div
        className="bg-white shadow-sm rounded w-14 h-14 flex items-center justify-center"
        style={{
          flex: '0 0 56px',
        }}
      >
        {icon}
      </div>
      <p className="text-primary-mainText text-sm mt-4 capitalize">
        {templateName}
      </p>

      {activeTemplate === templateName && (
        <div className="text-white bg-primary border-2 border-white absolute -right-2 -top-2 transition rounded-full w-6 h-6">
          <span className="inline-block w-5">
            <Icon name="Check" width={20} />
          </span>
        </div>
      )}
    </button>
  )
}
