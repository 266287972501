import { css as CSS } from '@codemirror/lang-css'
import CodeMirror from '@uiw/react-codemirror'
import { useCallback, useEffect, useState } from 'react'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'
// @ts-ignore
import { BackButton, FormFooter, Loader, PageHeadeline } from '../../components'
import {
  TEMPLATES_API,
  TEMPLATES_PATH,
  USER_PROFILE_URL
} from '../../constants'
import { useFetch, useToggle } from '../../hooks'
import { SaveChangesModal } from './components'
import { PreviewButton } from './components/PreviewButton'
import { ResetDropdown } from './components/ResetDropdown'
import { ITemplate, templateIState } from './interface'

export const GlobalCSS = () => {
  const [css, setCss] = useState<string>('')
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [globalCssTemplate, setGlobalCssTemplate] =
    useState<ITemplate>(templateIState)

  const { toggle: saveChangesModalToggle, visible: saveChangesModalVisible } =
    useToggle()
  const {
    apiCall: getGlobalCSSTemplate,
    response: global,
    loading
  } = useFetch('get')
  const { apiCall: updateGlobalCSSTemplate, loading: updateTemplateLoading } =
    useFetch('put')

  useEffect(() => {
    getGlobalCSSTemplate(`${TEMPLATES_API}/10`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (global) {
      setGlobalCssTemplate(global)
      setCss(global?.previewCss)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [global])

  const onCSSEditorChange = useCallback((value: string) => {
    setIsDirty(true)
    setCss(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onResetCssToDefault = () => {
    setIsDirty(true)
    setCss(globalCssTemplate?.defaultCss || '')
    showToast('success', 'Global CSS has been reset to default!')
  }

  const onResetCssToLast = () => {
    setIsDirty(true)
    setCss(globalCssTemplate?.css || '')
    showToast('success', 'Global CSS has been reset to published version!')
  }

  const onTemplateFormSubmit = () => {
    const templateObj = {
      ...globalCssTemplate,
      previewCss: css,
      css
    }

    updateGlobalCSSTemplate(
      `${TEMPLATES_API}/${globalCssTemplate?.id}`,
      templateObj,
      (response) => {
        showToast('success', 'Global CSS updated successfully!')
        setIsDirty(false)
        setGlobalCssTemplate(response)
        saveChangesModalToggle()
      },
      (err) => {
        console.log('error', err)
      }
    )
  }

  // Update default css (only for devs!)
  // const onTemplateDefaultFormSubmit = () => {
  //   const templateObj = {
  //     ...globalCssTemplate,
  //     defaultCss: css,
  //   };

  //   updateTemplate(
  //     `https://templates.porta.gjirafa.dev/api/v2/templates/${globalCssTemplate?.id}`,
  //     templateObj,
  //     () => {
  //       showToast('success', "Template updated successfully!");
  //     },
  //     (err) => {
  //       console.log("error", err);
  //     }
  //   );
  // };

  const onTemplatePreview = () => {
    const templateObj = {
      ...globalCssTemplate,
      previewCss: css
    }

    updateGlobalCSSTemplate(
      `${TEMPLATES_API}/${globalCssTemplate?.id}`,
      templateObj,
      () => {
        // showToast('success', "Template updated successfully!");
      },
      (err) => {
        console.log('error', err)
      }
    )

    window.open(`${USER_PROFILE_URL}?mode=preview` || '', '_blank')
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="h-full flex flex-col">
      <div>
        <BackButton to={TEMPLATES_PATH} title="Go Back" children={undefined} />
        <PageHeadeline title="Global CSS" description="" />
      </div>
      <div className="border border-primary-stroke rounded mt-6">
        <div className="border-b border-primary-stroke py-6 px-4 flex justify-end bg-white">
          <div className="flex items-center gap-4">
            <PreviewButton onClick={onTemplatePreview} />
            <ResetDropdown
              onDefaultReset={onResetCssToDefault}
              onPreviousReset={onResetCssToLast}
            />
          </div>
        </div>
        <div className="editor__container">
          <CodeMirror
            value={css}
            theme="dark"
            // onUpdate={(value: any) => onEditorUpdate(value)}
            extensions={[CSS()]}
            onChange={onCSSEditorChange}
          />
        </div>

        <FormFooter
          onSubmit={saveChangesModalToggle}
          disabled={!isDirty}
          loading={updateTemplateLoading}
          mtAuto
          pb="6"
          pt="6"
          className="py-6 px-4 sticky bottom-0 bg-white"
          saveBtnText="Save & Publish"
        />
      </div>
      <SaveChangesModal
        isVisible={saveChangesModalVisible}
        toggleModal={saveChangesModalToggle}
        onSubmit={onTemplateFormSubmit}
        loading={updateTemplateLoading}
        templateName={'Global CSS'}
      />
    </div>
  )
}
