import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
//@ts-ignore
import { showToast } from '@nodus/utilities-front'
import {
  BackButton,
  Container,
  FormFooter,
  Loader,
  Paragraph,
  ParagraphSize,
  SectionTitle
} from '../../components'
import {
  MODELS_PATH,
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS
} from '../../constants'
import { useFetch } from '../../hooks'
import {
  ModelActions,
  ModelMainInfo,
  ModelProperties,
  ModelType
} from './components'
import {
  expectedSuccessStatusCodesData,
  requestMethodTypes
} from './dummy-data'
import {
  IAction,
  ICreateModel,
  IExpectedStatusCode,
  IProperty,
  IVisibilitySettings
} from './interface'
import { handleErrors } from './utils/handle-errors'

export const ModelEdit = () => {
  const navigate = useNavigate()
  let { modelId } = useParams()
  const [actions, setActions] = useState()
  const [properties, setProperties] = useState()
  const [isMultiOption, setMultiOption] = useState(false)

  const methods = useForm()

  const { handleSubmit, reset } = methods

  const { apiCall: getModelDetails, loading, response } = useFetch('get')
  const { apiCall: updateModel, loading: updateModelLoading } = useFetch('put')

  useEffect(() => {
    if (!!modelId) {
      getModelDetails(
        `${USER_PROFILE_API}/integration-models/${modelId}`,
        USER_PROFILE_API_HEADERS
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId])

  useEffect(() => {
    reset(response?.data)
    setProperties(response?.data?.body?.properties)
    setMultiOption(response?.data?.isMultiOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  // const model = response?.data;
  const modelBody = response?.data?.body

  const onEditModel: SubmitHandler<any> = (
    data: ICreateModel,
    submitType: any
  ) => {
    const actions = data?.actions?.map((action) => {
      const visibilitySettings = action?.visibilitySettings?.map(
        (vbSettings) => {
          return {
            ...vbSettings,
            propertyName: vbSettings?.property?.name
          }
        }
      )

      const outputs = action?.outputs?.map((output) => {
        return {
          ...output,
          propertyName: output?.property?.name
        }
      })

      const expectedSuccessStatusCodes =
        action?.expectedSuccessStatusCodes?.map(
          (statusCode: IExpectedStatusCode) => statusCode.value
        )

      return {
        ...action,
        methodType: action?.methodType?.value,
        headers: action?.actionType.id === 2 ? action?.headers : null,
        visibilitySettings: action?.showVisibilitySettings
          ? visibilitySettings
          : null,
        expectedSuccessStatusCodes,
        outputs: action?.addActionOutput ? outputs : null
      }
    })

    const properties = data?.properties
      ?.filter((property) => !property.isDefault)
      .map((property: IProperty) => {
        return {
          ...property,
          childBody:
            property?.nested?.length > 0
              ? {
                  properties: property?.nested
                }
              : null
        }
      })

    const updatedObject = {
      icon: data?.icon,
      name: data?.name,
      description: data?.description,
      modelId: data?.modelId,
      alias: data?.alias,
      isMultiOption,
      id: response?.data?.id,
      body: {
        id: response?.data?.id,
        actions,
        properties
      }
    }

    updateModel(
      `${USER_PROFILE_API}/integration-models`,
      updatedObject,
      () => {
        showToast('success', 'Model updated successfully!')
        if (submitType === 'customize')
          navigate(`${MODELS_PATH}/${modelId}/options`)
        else navigate(MODELS_PATH)
      },
      (err) => {
        handleErrors(err)
      },
      USER_PROFILE_API_HEADERS
    )
  }

  useEffect(() => {
    const transformActions = modelBody?.actions?.map((action: IAction) => {
      const visibilitySettings = action?.visibilitySettings?.map(
        (item: IVisibilitySettings) => {
          return {
            ...item,
            property: {
              name: item.propertyName,
              id: item.propertyId
            }
          }
        }
      )

      const outputs = action?.outputs?.map((output) => {
        return {
          ...output,
          property: {
            name: output.propertyName,
            id: output.propertyId
          }
        }
      })

      const expectedSuccessStatusCodes =
        action?.expectedSuccessStatusCodes?.map((statusCode: any) => {
          return {
            label: expectedSuccessStatusCodesData?.find(
              (status) => status.value === statusCode
            )?.label,
            value: statusCode
          }
        })

      const methodType = {
        label: requestMethodTypes?.find(
          (method: any) => method.value === action?.methodType
        )?.label,
        value: action?.methodType
      }

      return {
        ...action,
        visibilitySettings,
        outputs,
        expectedSuccessStatusCodes,
        methodType
      }
    })
    setActions(transformActions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  if (loading) {
    return <Loader />
  }

  return (
    <Container>
      <div>
        <BackButton
          to={MODELS_PATH}
          className="mb-4"
          label="Back to Models"
          children={undefined}
        />

        <div className="mb-10">
          <SectionTitle noMargin className="flex">
            Edit Model
          </SectionTitle>
          <Paragraph parahraphSize={ParagraphSize.sm}>
            Edit a custom model to change the data properties and retrieve
            additional data points that you wish to display in the users'
            profiles.
          </Paragraph>
        </div>
      </div>

      <form action="">
        <FormProvider {...methods}>
          <ModelMainInfo isFromEdit />

          <ModelType isFromEdit isMultiOption={isMultiOption} />

          <ModelProperties
            isFromEdit
            isMultiOption={isMultiOption}
            propertiesData={properties}
          />

          {!isMultiOption && <ModelActions isFromEdit actionsData={actions} />}
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit(onEditModel)}
          loading={updateModelLoading}
          disabled={updateModelLoading}
          hasOptionalDropdown={isMultiOption}
          saveBtnText="Save"
          dropdownOptionOne="Save"
          dropdownOptionTwo="Save & Customize"
        />
      </form>
    </Container>
  )
}
