import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ArrowPosition,
  Card,
  CardSpace,
  CardStyle,
  Loader,
  SeeMore,
  Table,
  Td,
  Tooltip,
  TooltipPosition,
  Tr
} from '../../components'
import Search from '../../components/form/Search'
import { useCurrentPage, useFetch } from '../../hooks'
//@ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { USER_PROFILE_MODELS_API } from '../../constants'

interface ModelEntriesProps {
  modelAlias: string
}

export const ModelEntries: React.FC<ModelEntriesProps> = ({ modelAlias }) => {
  let { modelId } = useParams()

  const {
    apiCall: getModelEntries,
    loading: listLoading,
    response: modelEntries
  } = useFetch('get')

  const { currentPage, handlePageChange } = useCurrentPage()
  const totalCount = !!modelEntries && modelEntries.totalCount
  const PER_PAGE = 10
  const [emailFilter, setEmailFilter] = useState('')

  const getTableHeaders = () => {
    const headers: string[] = []

    if (modelEntries && modelEntries.userModelData?.length) {
      const firstRow = modelEntries.userModelData[0]
      Object.keys(firstRow).forEach((key) => {
        if (key !== 'actions') headers.push(key)
      })
    }
    return headers
  }

  const getTableCell = (row: any, header: string) => {
    const isColumnObject =
      row[header] &&
      (typeof row[header] === 'object' || typeof row[header][0] === 'object')

    return (
      <Td key={header}>
        {row[header] && !isColumnObject ? (
          <SeeMore className="text-sm" fullText={row[header]} breakpoint={25} />
        ) : !isColumnObject ? (
          ''
        ) : (
          <Tooltip
            text={JSON.stringify(row[header], null, 2)}
            innerClassName="overflow-x-auto mb-0 max-w-md max-h-80 z-50 pb-3 top-5 custom-object__tooltip"
            className="text-sm text-primary-secText ml-2 w-fit"
            tooltipPosition={TooltipPosition.underTooltip}
            arrowPosition={ArrowPosition.top}
            bottom="bottom-0"
            preserveText
          >
            <div className="flex items-center gap-1">
              <span className="text-sm text-primary-mainText">Object item</span>
              <Icon name="Info" />
            </div>
          </Tooltip>
        )}
      </Td>
    )
  }

  const getModelEntriesData = () => {
    getModelEntries(
      `${USER_PROFILE_MODELS_API}/${
        process.env.REACT_APP_ORGANIZATION
      }/${modelAlias}/list?page=${currentPage}&pageSize=${PER_PAGE}&email=${encodeURI(
        emailFilter
      )}`
    )
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') getModelEntriesData()
  }

  useEffect(() => {
    getModelEntriesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, currentPage])

  useEffect(() => {
    getTableHeaders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelEntries])

  if (listLoading && !modelEntries?.userModelData) {
    return <Loader />
  }

  return (
    <Card
      cardStyle={CardStyle.bordered}
      cardSpace={CardSpace.xl}
      className="mt-8 model-entries__card"
    >
      <div className="flex justify-between items-center">
        <h3 className="text-xl text-primary-mainText font-medium">
          Browse Model Entries
        </h3>
        <div className="relative">
          <Search
            value={emailFilter}
            placeholder="Enter full email address"
            onChange={(e: any) => setEmailFilter(e.target.value)}
            onSearchBtnClick={getModelEntriesData}
            onKeyUp={handleKeyPress}
          />
          <div className="pt-1 pr-0.5 w-4 h-4 right-12 top-3 flex absolute items-center justify-center">
            <Tooltip
              text="The filter is applied with exact match rules. Please enter the entire email address when searching for a user."
              innerClassName="w-80"
              className="text-sm text-gray-500 hover:text-primary-secText focus:text-primary-secText transition"
            >
              <span className="inline-block">
                <Icon name="Info" width={14} />
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto relative entries-table__container">
        <Table
          loading={listLoading}
          names={getTableHeaders().map((header) => ({ children: header }))}
          values={modelEntries?.userModelData}
          wrapperClassName="w-max min-w-full mt-8 static horizontal-scroll entries__table"
          loaderClassName="flex items-center justify-center"
          renderTr={(el: any) => {
            return (
              <Tr>
                {getTableHeaders().map((header) => getTableCell(el, header))}
              </Tr>
            )
          }}
          pagination={{
            pageSize: PER_PAGE,
            totalCount,
            onPageChange: handlePageChange,
            page: currentPage,
            length: PER_PAGE,
            className: 'w-max sticky left-0 border-none'
          }}
          emptyView={{ text: 'There are no entries for this model.' }}
        />
      </div>
    </Card>
  )
}
