import { useEffect } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch
} from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import {
  ArrowPosition,
  BackButton,
  Container,
  FormControl,
  FormFooter,
  Input,
  InputSize,
  Label,
  Loader,
  Message,
  Paragraph,
  ParagraphSize,
  SectionTitle,
  Tooltip,
  TooltipPosition
} from '../../components'
import {
  MODELS_PATH,
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS
} from '../../constants'
import { useFetch } from '../../hooks'
import { cx, inputClasses, reactSelectStyle, validateUrl } from '../../utils'
import {
  frequencyValues,
  propertyAccessorInformation,
  responseTypes,
  scheduleTypes
} from './dummy-data'
import { handleErrors } from './utils/handle-errors'
//@ts-ignore
import { GjIconReact as Icon, showToast } from '@nodus/utilities-front'
import { DataImportHeaders, DataImportSchedule } from './components'
import { IPipelineJob, IResponseType, IScheduleType } from './interface'

export const DataImport = () => {
  const navigate = useNavigate()
  const methods = useForm()
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset
  } = methods

  const { apiCall: createPipelineJob, loading } = useFetch('post')

  let { modelId } = useParams()
  const {
    apiCall: getPipelineJob,
    loading: pipelineLoading,
    response: pipelineDetails
  } = useFetch('get')

  useEffect(() => {
    getPipelineJob(
      `${USER_PROFILE_API}/integration-models/rest-pipeline-job?integrationStructureId=${modelId}`,
      USER_PROFILE_API_HEADERS
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId])

  const pipelineData = pipelineDetails?.data

  useEffect(() => {
    const dataImportData = {
      ...pipelineData,
      scheduleId: getScheduleType(pipelineData?.scheduleId),
      responseType: getResponseType(pipelineData?.responseType),
      scheduleFrequency:
        pipelineData?.scheduleId === 3
          ? getScheduleFrequency(pipelineData?.scheduleFrequency)
          : null
    }
    reset(dataImportData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineData])

  const onDataImportSubmit: SubmitHandler<any> = (data: IPipelineJob) => {
    const headers = data?.headers?.reduce((obj: any, item: any) => {
      obj[item.key] = item.value
      return obj
    }, {})

    const scheduleFrequency =
      data?.scheduleId?.label === 'Hourly' ? data?.scheduleFrequency?.value : 0
    const propertyAccessor =
      data?.responseType?.value !== 'txt/csv' ? data?.propertyAccessor : null

    const requestObject = {
      ...data,
      id: pipelineData?.id || 0,
      responseType: data?.responseType?.value,
      scheduleId: data?.scheduleId?.value,
      propertyAccessor,
      scheduleFrequency,
      headers: headers || {}
    }

    createPipelineJob(
      `${USER_PROFILE_API}/integration-models/rest-pipeline-job?integrationStructureId=${modelId}`,
      requestObject,
      () => {
        pipelineData?.id
          ? showToast('success', 'Data import edited successfully!')
          : showToast('success', 'Data import created successfully!')
        navigate(`${MODELS_PATH}/${modelId}/data-import-details`)
      },
      (err) => {
        handleErrors(err)
      },
      USER_PROFILE_API_HEADERS
    )
  }

  const [responseType] = useWatch({
    control,
    name: ['responseType']
  })

  const getResponseType = (value: string) => {
    return responseTypes?.find((el: IResponseType) => el.value === value)
  }
  const getScheduleType = (value: number) => {
    return scheduleTypes?.find((el: IScheduleType) => el.value === value)
  }
  const getScheduleFrequency = (value: number) => {
    return frequencyValues?.find((el: IScheduleType) => el.value === value)
  }

  if (pipelineLoading) {
    return <Loader />
  }

  return (
    <Container>
      <div>
        <BackButton
          to={MODELS_PATH}
          className="mb-4"
          label="Back to Models"
          children={undefined}
        />
        <div className="mb-10">
          <SectionTitle noMargin className="flex">
            {pipelineData?.id ? 'Edit Data Import' : 'Create Data Import'}
          </SectionTitle>
          <Paragraph parahraphSize={ParagraphSize.sm}>
            Create a data import to bring in existing data from external sources
            into the models and display it in the users' profiles.
          </Paragraph>
        </div>
      </div>
      <form action="">
        <FormProvider {...methods}>
          <FormControl>
            <Label required text="Url" htmlFor="url" />
            <Input
              id="url"
              {...register('getDataEndpoint', {
                required: 'Url is required!',
                validate: (value) =>
                  validateUrl(value) || 'Please enter a valid URL.'
              })}
              error={!!errors.getDataEndpoint && errors.getDataEndpoint.message}
              inputSize={InputSize.sm}
              className={inputClasses}
              autoFocus
              placeholder="Url here"
            />
          </FormControl>

          <FormControl>
            <Label required text="Response Type" />
            <Controller
              rules={{
                required: true
              }}
              name="responseType"
              control={control}
              render={({ field: { onChange, ref, value } }) => (
                <Select
                  noOptionsMessage={() => 'No response type found.'}
                  options={responseTypes}
                  getOptionLabel={(x) => x?.label}
                  getOptionValue={(x) => x?.value}
                  isSearchable
                  onChange={onChange}
                  ref={ref}
                  value={value}
                  menuPosition="fixed"
                  menuPortalTarget={
                    document?.querySelector(
                      '.porta-app__container'
                    ) as HTMLElement
                  }
                  className={cx([
                    'text-sm',
                    errors?.responseType && 'invalid-field'
                  ])}
                  classNamePrefix="porta-react-select"
                  placeholder="Select Response Type"
                  styles={reactSelectStyle}
                />
              )}
            />
            {errors?.responseType && (
              <Message message="Response Type is required" />
            )}
          </FormControl>

          {responseType?.value !== 'txt/csv' && (
            <FormControl>
              <div className="flex items-center relative">
                <Label
                  text="Property Accessor (Optional)"
                  htmlFor="property-accessor"
                />
                <Tooltip
                  text={propertyAccessorInformation}
                  innerClassName="h-80 mb-0 w-fit-content z-50 pb-3 top-5"
                  className="text-sm text-primary-secText ml-2 w-5"
                  tooltipPosition={TooltipPosition.underTooltip}
                  arrowPosition={ArrowPosition.top}
                  bottom="bottom-0"
                  preserveText
                >
                  <Icon name="Info" />
                </Tooltip>
              </div>
              <Input
                id="property-accessor"
                {...register('propertyAccessor')}
                inputSize={InputSize.sm}
                className={inputClasses}
                placeholder="Property accessor here"
              />
            </FormControl>
          )}

          <DataImportHeaders />

          <DataImportSchedule />
        </FormProvider>

        <FormFooter
          onSubmit={handleSubmit(onDataImportSubmit)}
          loading={loading}
          disabled={loading}
        />
      </form>
    </Container>
  )
}
