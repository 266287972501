import { FC, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { TEMPLATES_PATH } from '../../../constants'
import { cx } from '../../../utils'
import { ITemplate } from '../interface'
// @ts-ignore
import { GjIconReact as Icon } from '@nodus/utilities-front'
import { format } from 'date-fns'

interface ITemplateItem {
  template: ITemplate
  searchParam: string
}

export const TemplateItem: FC<ITemplateItem> = ({ template, searchParam }) => {
  const [clicked, setClicked] = useState(false)
  const contentEl = useRef<HTMLDivElement | null>(null)

  const handleToggle = () => {
    setClicked((prev) => !prev)
  }

  return (
    <>
      <NavLink
        to={`${TEMPLATES_PATH}/${template?.id}${
          searchParam && `?type=${searchParam}`
        }`}
        className={cx([
          'flex items-center relative py-3 px-2 border mb-3 rounded group transition hover:bg-gray-50 focus:shadow-outlineGray focus:outline-none',
          clicked
            ? 'border-gray-400 shadow-md'
            : 'border-primary-stroke shadow-sm'
        ])}
      >
        <div className="bg-gray-100 border-r border-primary-stroke flex items-center justify-center template-icon">
          {template?.icon !== '' ? (
            <img src={template?.icon} alt="" className="w-1/2" />
          ) : (
            <span className="text-sm text-primary-secText">
              {template?.name?.charAt(0).toUpperCase()}
            </span>
          )}
        </div>

        <div className="flex items-center pl-10">
          <h6 className="ml-3 w-96 text-sm font-medium text-primary-mainText">
            {template?.name}
          </h6>

          <p className="text-xs text-primary-secText w-32">
            {template?.updated
              ? format(new Date(template?.updated), 'MMMM dd, yyyy')
              : ''}
          </p>

          <p className="text-xs text-primary-secText">{template?.updatedBy}</p>
        </div>

        <div className="w-8 flex items-center justify-center absolute right-2">
          {template &&
            template.components &&
            template?.components?.length > 0 && (
              <button
                type="button"
                className={cx([
                  'w-7 h-7 hover:bg-gray-50 transition focus:shadow-outlineGray focus:outline-none rounded border border-transparent hover:border-primary-stroke flex items-center justify-center'
                ])}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleToggle()
                }}
              >
                <span
                  className={cx([
                    'w-5 inline-block',
                    clicked && 'rotate -rotate-180 transition'
                  ])}
                >
                  <Icon name="ArrowDown" />
                </span>
              </button>
            )}
        </div>
      </NavLink>

      {template && template?.components && template?.components?.length > 0 && (
        <div
          ref={contentEl}
          className={cx(['transition-all', clicked ? '' : 'overflow-hidden'])}
          style={
            clicked
              ? {
                  height: contentEl?.current?.scrollHeight,
                  marginTop: contentEl?.current?.style?.marginTop
                }
              : { height: '0px' }
          }
        >
          <div className="mb-6 pl-[45px]">
            {template?.components?.map((item, index) => (
              <NavLink
                key={index}
                to={`${TEMPLATES_PATH}/${item?.id}${
                  searchParam && `?type=${searchParam}`
                }`}
                className="shadow-sm focus:shadow-outlineGray focus:outline-none border border-primary-stroke py-2 pl-4 transition hover:bg-gray-50 flex items-center rounded nested-template mb-2 relative group"
              >
                <p className="text-xs w-96 text-primary-mainText">
                  {item.name}
                </p>
                <p className="text-xs text-primary-secText w-32">
                  {item?.updated
                    ? format(new Date(item?.updated), 'MMMM dd, yyyy')
                    : ''}
                </p>
                <p className="text-xs text-primary-secText">{item.updatedBy}</p>
              </NavLink>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
