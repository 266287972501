export const USER_PROFILE_URL = process.env.REACT_APP_USER_PROFILE
export const USER_PROFILE_MAIN_PATH =
  process.env.REACT_APP_USER_PROFILE_MAIN_PATH
export const USER_PROFILE_API = process.env.REACT_APP_USER_PROFILE_API
export const TEMPLATES_API = `${process.env.REACT_APP_TEMPLATES_API}/${process.env.REACT_APP_TEMPLATES_API_VERSION}/templates`
export const USER_PROFILE_MODELS_API =
  process.env.REACT_APP_USER_PROFILE_MODELS_API
export const ORGANIZATION = process.env.REACT_APP_ORGANIZATION
export const USER_PROFILE_API_HEADERS = {
  headers: {
    'x-organization-id': ORGANIZATION
  }
}

export const BASE_PATH = '/porta/gjirafa/customization'
export const UNIVERSAL_LOGIN_PATH = `${BASE_PATH}/universal-login`
export const TEMPLATES_PATH = `${BASE_PATH}/templates`
export const GLOBAL_CSS_PATH = `${BASE_PATH}/templates/global-css`

export const MODELS_PATH = `${BASE_PATH}/models`
export const MODELS_CREATE_PATH = `${MODELS_PATH}/create`

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api`
