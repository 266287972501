import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Label, RadioButton } from '../../../../components';
import InfoFillPrimary from '../../../../components/Icons/InfoFillPrimary';
import { cx } from '../../../../utils';

interface IModelType {
  isFromEdit?: boolean;
  isMultiOption?: boolean;
}

export const ModelType: FC<IModelType> = ({ isFromEdit, isMultiOption }) => {
  const { control } = useFormContext();

  return (
    <>
      <Label required text="Model Type" />
      <div className="flex gap-2">
        <div
          className={cx([
            'px-4 py-2 bg-primary-bg rounded',
            !isMultiOption && 'font-medium',
            isFromEdit && 'bg-gray-300 pointer-events-none',
          ])}
        >
          <Controller
            control={control}
            name="modelType"
            render={({ field: { onChange, value, ref } }) => (
              <RadioButton
                id="model-type-standard"
                label="Standard Model"
                value="standard"
                disabled={isFromEdit}
                onChange={onChange}
                inputRef={ref}
                checked={isFromEdit && !isMultiOption ? true : value === 'standard'}
              />
            )}
          />
        </div>

        <div
          className={cx([
            'px-4 py-2 bg-primary-bg rounded',
            isMultiOption && 'font-medium',
            isFromEdit && 'bg-gray-300 pointer-events-none',
          ])}
        >
          <Controller
            control={control}
            name="modelType"
            render={({ field: { onChange, value, ref } }) => (
              <RadioButton
                id="model-type-multioption"
                label="MultiOption Model"
                value="multioption"
                onChange={onChange}
                disabled={isFromEdit}
                inputRef={ref}
                checked={isFromEdit && isMultiOption ? true : value === 'multioption'}
              />
            )}
          />
        </div>
      </div>

      {!isFromEdit && (
        <div className="mt-4 gap-2 px-3 py-4 flex items-start rounded bg-primary-100 border border-l-4 border-blue-50 border-l-blue-500">
          <InfoFillPrimary width={20} />
          <p className="text-sm text-primary-mainText font-normal">
            Changing the model from Standard to MultiOption will remove Property
            [Name] because its DataType is [Object, Object Array].
          </p>
        </div>
      )}
    </>
  );
};
