import { Card, CardSpace, CardStyle, Label } from '../../components';
import { Nested } from '../../components/Icons';
import { cx } from '../../utils';
import { ModelActionsDetails, ModelDetailsItem } from './components';
import { useModelDetailsContext } from './context/ModelDetailsContext';
import { IProperty } from './interface';

export const ModelDetails = () => {
  const { model } = useModelDetailsContext();
  const properties = model?.body?.properties;

  const isFieldTypeObject = (property: IProperty) => {
    return (
      properties &&
      (property?.inputType?.id === 6 || property?.inputType?.id === 7)
    );
  };

  return (
    <Card
      cardStyle={CardStyle.bordered}
      cardSpace={CardSpace.xl}
      className="mt-8"
    >
      <ModelDetailsItem label="Name" value={model?.name} />
      <ModelDetailsItem label="Description" value={model?.description} />
      <ModelDetailsItem label="Alias" value={model?.alias} />
      <ModelDetailsItem label="Model Type" value={model?.isMultiOption ? 'Multi Option' : 'Standard'} />

      {model?.icon && (
        <div className="mb-6">
          <Label text="Icon" />
          <div className="border-2 border-primary-stroke rounded p-2 inline-block">
            <img src={model?.icon} alt={`${model?.name} icon`} width={40} />
          </div>
        </div>
      )}

      <h3 className="text-xl text-primary-mainText font-medium mb-4">
        Model Properties
      </h3>
      <div>
        <div className="grid grid-cols-4 border-b">
          <Label text="Property Name" />
          <Label text="Data Type" />
          <Label text="Designation Type" />
          <Label text="Mode" />
        </div>
        {properties?.map((property: IProperty, index: number) => {
          const lastChild = index === properties?.length - 1;

          return (
            <div key={index}>
              <div
                className={cx([
                  'grid grid-cols-4 py-2 ml-2 relative',
                  !lastChild && !isFieldTypeObject(property) && 'border-b',
                ])}
              >
                {isFieldTypeObject(property) && (
                  <div className="absolute top-1/2 -left-5">
                    <Nested width="16" height="16" />
                  </div>
                )}
                <p className="text-sm">{property?.name}</p>
                <p className="text-sm">{property?.inputType?.name}</p>
                <div className="text-sm">
                  {isFieldTypeObject(property)
                    ? '-'
                    : property?.designationType?.name || (
                        <p className="italic text-sm">empty</p>
                      )}
                </div>
                <p className="text-sm">
                  {property?.isRequired ? 'Required' : 'Nullable'}
                </p>
              </div>
              {isFieldTypeObject(property) && (
                <div className="bg-primary-bg rounded border border-primary-stroke pl-2">
                  {property?.childProperties?.map(
                    (child: IProperty, childIndex: number) => {
                      const lastChildProperty =
                        childIndex === property?.childProperties?.length - 1;
                      return (
                        <div
                          className={cx([
                            'grid grid-cols-4 py-2.5',
                            !lastChildProperty && 'border-b',
                          ])}
                          key={child?.id}
                        >
                          <p className="text-sm">{child?.name}</p>
                          <p className="text-sm">{child?.inputType?.name}</p>
                          <p className="text-sm">-</p>
                          <p className="text-sm">
                            {child?.isRequired ? 'Required' : 'Nullable'}
                          </p>
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {model?.body?.actions.length > 0 && (
        <ModelActionsDetails actions={model?.body?.actions} />
      )}
    </Card>
  );
};
