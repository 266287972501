import { FC, useEffect } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import Select from 'react-select';
import {
  Checkbox,
  Input,
  InputSize,
  Message,
  MessageTypes
} from '../../../../components';
import { cx, inputClasses, reactSelectStyle } from '../../../../utils';
import { IInputType, IProperty, nestedPropertyIState } from '../../interface';
import { AddNewButton } from '../model-form-ui/AddNewButton';
import { RemoveButton } from '../model-form-ui/RemoveButton';

interface IChildProperty {
  nestedIndex: number;
  inputTypes: IInputType[];
  childProperties: IProperty[];
  isFromEdit: boolean;
}

export const ChildProperties: FC<IChildProperty> = ({
  nestedIndex,
  inputTypes,
  childProperties,
  isFromEdit,
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, remove, append, replace } = useFieldArray({
    control,
    name: `properties[${nestedIndex}].nested`,
  });

  const [properties, childs] = useWatch({
    name: ['properties', `properties[${nestedIndex}].nested`],
  });

  useEffect(() => {
    isFromEdit && replace(childProperties || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replace, childProperties]);

  const appendItem = () => {
    append(nestedPropertyIState);
  };

  const removeProperty = (index: number) => {
    remove(index);
  };

  const removePropertyButton = (condition: boolean, index: number) => {
    if (condition)
      return <RemoveButton onClick={() => removeProperty(index)} />;
    return <div className="w-6 h-6"></div>;
  };

  const propertiesName = childs?.map((property: IProperty) => property.name);

  const childPropertiesLength = childProperties?.length || 0;

  return (
    <div className="-mt-2 mb-4 py-3 pl-4 bg-primary-bg rounded-lg">
      <p className="text-primary-secText font-medium text-sm pl-2">
        {properties[nestedIndex]?.name || 'undefined'}'s child
      </p>
      {fields?.map((field, index) => {
        // @ts-ignore
        const propertyChildErrors = errors?.properties?.[nestedIndex]?.nested?.[index];
        return (
          <div
            className="flex justify-between py-2 px-1 hover:bg-gray-50 transition rounded gap-4"
            key={field?.id}
          >
            <div className="flex-1">
              <Input
                {...register(
                  `properties[${nestedIndex}].nested[${index}].name`,
                  {
                    required: 'This field is required',
                    validate: {
                      notLowerCase: (value: string) =>
                        /^[a-z_]+$/.test(value) ||
                        "Property must be lowercase, can't contain spaces, numbers or special characters",
                      notValidPropertyName: (value: string) =>
                        propertiesName.indexOf(value) ===
                          propertiesName.lastIndexOf(value) ||
                        'Property Name needs to be unique',
                    },
                  }
                )}
                inputSize={InputSize.sm}
                className={inputClasses}
                type="text"
                maxLength={300}
                disabled={isFromEdit && index + 1 <= childPropertiesLength}
                placeholder="Enter Child Property Name"
                error={
                  !!propertyChildErrors?.name &&
                  propertyChildErrors?.name?.message
                }
              />
            </div>

            <div className="flex-1 max-w-xs">
              <Controller
                control={control}
                name={`properties[${nestedIndex}].nested[${index}].inputType`}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    options={inputTypes || []}
                    getOptionLabel={(x) => x?.name}
                    getOptionValue={(x) => x?.id}
                    isSearchable
                    ref={ref}
                    value={!!value?.name ? value : null}
                    menuPosition="fixed"
                    onChange={onChange}
                    isDisabled={
                      isFromEdit && index + 1 <= childPropertiesLength
                    }
                    className={cx([
                      'text-sm',
                      propertyChildErrors?.inputType && 'invalid-field',
                    ])}
                    classNamePrefix="porta-react-select"
                    placeholder="Select Data Type"
                    styles={reactSelectStyle}
                  />
                )}
              />

              {propertyChildErrors?.inputType && (
                <Message
                  containerClassName="mt-2"
                  type={MessageTypes.error}
                  message="Data Type is required."
                />
              )}
            </div>
            <div className="flex-1"></div>

            {!isFromEdit && (
              <div className="w-14 text-center">
                <Controller
                  control={control}
                  name={`properties[${nestedIndex}].nested[${index}].isRequired`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Checkbox
                      label=""
                      id={`required-${index}`}
                      checked={value || false}
                      inputRef={ref}
                      onChange={onChange}
                      wrapperClassName="mt-3"
                    />
                  )}
                />
              </div>
            )}

            {isFromEdit && (
              <>
                {index + 1 <= childPropertiesLength ? (
                  <div className="w-14 text-center">
                    <Controller
                      control={control}
                      name={`properties[${nestedIndex}].nested[${index}].isRequired`}
                      render={({ field: { onChange, value, ref } }) => (
                        <Checkbox
                          label=""
                          id={`nested-required-${index}`}
                          checked={value || false}
                          disabled={
                            isFromEdit && index + 1 <= childPropertiesLength
                          }
                          inputRef={ref}
                          onChange={onChange}
                          wrapperClassName="mt-3"
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div className="w-14"></div>
                )}
              </>
            )}

            {isFromEdit
              ? removePropertyButton(index + 1 > childPropertiesLength, index)
              : removePropertyButton(index > 0, index)}
          </div>
        );
      })}
      <AddNewButton text="Add Child Property" onClick={appendItem} />
    </div>
  );
};
