import { FC, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { BackButton, Loader, Modal, Tooltip } from "../../../components";
import { ModalSize } from "../../../components/modal";
import { UNIVERSAL_LOGIN_PATH } from "../../../constants";
import { cx } from "../../../utils";
import { ArrowPosition, TooltipPosition } from "../../../components/tooltip";

export interface IUseLocation {
  branding: {
    buttonTextColor: string;
    companyLogo: string;
    copyRightTextColor: string;
    pageBgColor: string;
    primaryColor: string;
  };
}

interface IBrandingPreviewModal {
  toggle: () => void;
  visible: boolean;
}

export const BrandingPreviewModal: FC<IBrandingPreviewModal> = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [activeView, setActiveView] = useState<string>("desktop");
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { state } = useLocation() as any;
  const {
    buttonTextColor,
    companyLogo,
    copyRightTextColor,
    pageBgColor,
    primaryColor,
  } = !!state?.branding && state?.branding;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.onpopstate = (e: any) => {
      navigate(`${UNIVERSAL_LOGIN_PATH}/${id || "create"}`);
      props.toggle();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <>
      <Modal
        hide={props.toggle}
        visible={props.visible}
        title=""
        modalSize={ModalSize.unset}
        noCloseButton
        noHeader
        fullHeight
      >
        <div className="bg-primary-mainText py-4 px-6 text-center relative">
          <BackButton
            to="#"
            onClick={props.toggle}
            label="Go Back"
            className="text-white absolute py-2 px-3 left-6 top-2 hover:bg-white hover:text-primary-mainText focus:shadow-outlinePrimary"
          >
            <></>
          </BackButton>

          <p className="font-medium text-white">Preview mode</p>
        </div>

        <div>
          {showLoader && (
            <Loader className="absolute h-full w-full left-0 top-0" />
          )}

          <div className={cx([showLoader && "opacity-0"])}>
            <iframe
              id="iframe"
              width={windowDimensions.width}
              height={windowDimensions.height - 56}
              src={`${
                process.env.REACT_APP_AUTHORITY
              }/Account/LoginPreview?primaryColor=${primaryColor?.replace(
                "#",
                "%23"
              )}&pageBackgroundColor=${pageBgColor?.replace(
                "#",
                "%23"
              )}&copyrightTextColor=${copyRightTextColor?.replace(
                "#",
                "%23"
              )}&ButtonTextColor=${buttonTextColor?.replace(
                "#",
                "%23"
              )}&companyLogo=${companyLogo || ""}`}
              title="description"
              className="mx-auto transition border-r border-l border-dashed border-primary-tertText"
            ></iframe>
          </div>
        </div>

        <div className="hidden sm:flex absolute bottom-6 left-6 bg-white rounded">
          <Tooltip
            text="Desktop"
            arrowPosition={ArrowPosition.left}
            tooltipPosition={TooltipPosition.left}
          >
            <button
              type="button"
              className={cx([
                "p-1 w-8 h-8 border rounded-l relative group transition float-left focus:shadow-outlinePrimary",
                activeView === "desktop"
                  ? "text-white bg-primary border-primary"
                  : "text-primary-secText hover:text-primary border-primary-stroke",
              ])}
              onClick={() => {
                setActiveView("desktop");
                setWindowDimensions(getWindowDimensions());
              }}
            >
              {/* TODO: Should replace with the Icon  */}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current w-full h-full"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.55005 5.14531C2.55005 4.06836 3.42309 3.19531 4.50005 3.19531H13.5C14.577 3.19531 15.45 4.06836 15.45 5.14531V11.5739C15.45 12.6508 14.577 13.5239 13.5 13.5239H4.50005C3.42309 13.5239 2.55005 12.6508 2.55005 11.5739V5.14531ZM4.50005 4.09531C3.92015 4.09531 3.45005 4.56541 3.45005 5.14531V11.5739C3.45005 12.1538 3.92015 12.6239 4.50005 12.6239H13.5C14.0799 12.6239 14.55 12.1538 14.55 11.5739V5.14531C14.55 4.56541 14.0799 4.09531 13.5 4.09531H4.50005Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.40723 14.3602C6.40723 14.1116 6.6087 13.9102 6.85723 13.9102H11.1429C11.3915 13.9102 11.5929 14.1116 11.5929 14.3602C11.5929 14.6087 11.3915 14.8102 11.1429 14.8102H6.85723C6.6087 14.8102 6.40723 14.6087 6.40723 14.3602Z"
                />
              </svg>
            </button>
          </Tooltip>

          <Tooltip text="Tablet">
            <button
              type="button"
              className={cx([
                "p-1 w-8 h-8 border-t border-b transition float-left focus:shadow-outlinePrimary",
                activeView === "tablet"
                  ? "text-white bg-primary border-primary"
                  : "text-primary-secText hover:text-primary border-primary-stroke",
              ])}
              onClick={() => {
                setActiveView("tablet");
                setWindowDimensions({ ...windowDimensions, width: 768 });
              }}
            >
              {/* TODO: Should replace with the Icon  */}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current w-full h-full"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.83569 4.50078C3.83569 3.42383 4.70874 2.55078 5.78569 2.55078H12.2143C13.2912 2.55078 14.1643 3.42383 14.1643 4.50078V13.5008C14.1643 14.5777 13.2912 15.4508 12.2143 15.4508H5.78569C4.70874 15.4508 3.83569 14.5777 3.83569 13.5008V4.50078ZM5.78569 3.45078C5.20579 3.45078 4.73569 3.92088 4.73569 4.50078V13.5008C4.73569 14.0807 5.20579 14.5508 5.78569 14.5508H12.2143C12.7942 14.5508 13.2643 14.0807 13.2643 13.5008V4.50078C13.2643 3.92088 12.7942 3.45078 12.2143 3.45078H5.78569Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.00003 13.7154C9.35507 13.7154 9.64289 13.4276 9.64289 13.0725C9.64289 12.7175 9.35507 12.4297 9.00003 12.4297C8.64499 12.4297 8.35718 12.7175 8.35718 13.0725C8.35718 13.4276 8.64499 13.7154 9.00003 13.7154Z"
                />
              </svg>
            </button>
          </Tooltip>

          <Tooltip
            text="Mobile"
            arrowPosition={ArrowPosition.right}
            tooltipPosition={TooltipPosition.right}
          >
            <button
              type="button"
              className={cx([
                "p-1 w-8 h-8 border rounded-r transition float-left focus:shadow-outlinePrimary",
                activeView === "mobile"
                  ? "text-white bg-primary border-primary"
                  : "text-primary-secText border-primary-stroke hover:text-primary",
              ])}
              onClick={() => {
                setActiveView("mobile");
                setWindowDimensions({ ...windowDimensions, width: 480 });
              }}
            >
              {/* TODO: Should replace with the Icon  */}
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-current w-full h-full"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.69287 4.50078C4.69287 3.42383 5.56592 2.55078 6.64287 2.55078H11.3572C12.4341 2.55078 13.3072 3.42383 13.3072 4.50078V13.5008C13.3072 14.5777 12.4341 15.4508 11.3572 15.4508H6.64287C5.56592 15.4508 4.69287 14.5777 4.69287 13.5008V4.50078ZM6.64287 3.45078C6.06297 3.45078 5.59287 3.92088 5.59287 4.50078V13.5008C5.59287 14.0807 6.06297 14.5508 6.64287 14.5508H11.3572C11.9371 14.5508 12.4072 14.0807 12.4072 13.5008V4.50078C12.4072 3.92088 11.9371 3.45078 11.3572 3.45078H6.64287Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.6499 13.2859C7.6499 13.0374 7.85137 12.8359 8.0999 12.8359H9.92133C10.1699 12.8359 10.3713 13.0374 10.3713 13.2859C10.3713 13.5345 10.1699 13.7359 9.92133 13.7359H8.0999C7.85137 13.7359 7.6499 13.5345 7.6499 13.2859Z"
                />
              </svg>
            </button>
          </Tooltip>
        </div>
      </Modal>
    </>
  );
};
