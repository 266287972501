
import { v4 as uuidv4 } from "uuid";
  

export const createModelIntSample = {
  modelId: "Subscription_Id",
  name: "Subscriptions",
  image: "",
  description: "Lorem ipsum dolor sit amet",
  alias: "testsubscriptions",
  body: {
    actions: [
      {
        name: "Cancel",
        methodType: "subscription",
        url: "https://user-profile-porta.gjirafa.dev/swagger/index.html",
        headers: [
          {
            key: "test",
            value: "test",
          },
        ],
        visibilitySettings: [
          {
            propertyName: "title",
            operatorId: 1,
            value: "active",
          },
        ],
        outputs: [
          {
            propertyName: "title",
            value: "test value",
          },
        ],
        body: "",
        actionTypeId: 1,
        redirectInNewTab: true,
        showVisibilitySettings: true,
        addActionOutput: true,
        errorMessage: "string",
        expectedSuccessStatusCodes: "string",
      },
    ],
    properties: [
      {
        name: "title",
        inputTypeId: 1,
        isRequired: true,
      },
    ],
  },
};

export const dataTypes = [
  {
      label: "String",
      value: "string"
  },
  {
      label: "Boolean",
      value: "boolean"
  },
  {
      label: "Number",
      value: "number"
  },
]

export const modes = [
  {
      label: "Required",
      value: "required"
  },
  {
      label: "Nullable",
      value: "nullable"
  }
]


export const modelPropertiesData = [
  {
    id: uuidv4(),
    name: "User Id",
    required: false
  },
  {
    id: uuidv4(),
    name: "Image",
  },
];


export const modelTypes = [
  {
    name: "Newsletter",
    description:
      "Display newsletters for users to sign up to in user’s profile.",
    value: "newsletter",
  },
  {
    name: "Subscriptions",
    description: "Display user’s active subscriptions in user’s profile.",
    value: "subscriptions",
  },
  {
    name: "Comments",
    description: "Display user’s comments in user’s profile.",
    value: "comments",
  },
  {
    name: "Other",
    description: "",
    value: "other",
  },
];

export const modelActionTypes = [
  {
    name: "Button",
    description: "Call to action",
    id: 1
  },
  {
    name: "Hyperlink",
    description: "Redirect users to a new link",
    id: 2
  },
]

export const operators = [
  {
    label: "is",
    value: "is"
  },
  {
    label: "is not",
    value: "is not"
  },
  {
    label: "contains",
    value: "contains"
  },
  {
    label: "does not contain",
    value: "does not contain"
  }
]

export const requestMethodTypes = [
  {
    label: "POST",
    value: "post"
  },
  {
    label: "PUT",
    value: "put"
  },
  {
    label: "PATCH",
    value: "patch"
  },
  {
    label: "DELETE",
    value: "delete"
  }
]

export const expectedSuccessStatusCodesData = [
  {
    label: '1xx',
    value: 100
  },
  {
    label: '2xx',
    value: 200
  },
  {
    label: '3xx',
    value: 300
  },
  {
    label: '4xx',
    value: 400
  },
  {
    label: '5xx',
    value: 500
  }
]

export const modelsList = [
  {
    name: "Notifications",
    type: "Notifications",
    created: "April 5, 2023",
    status: "published",
    order: 0,
  },
  {
    name: "Subscriptions",
    type: "Subscriptions",
    created: "April 5, 2023",
    status: "Unpublished",
    order: 1,
  },
];

export const responseTypes = [
  {
    label: "Json",
    value: "application/json",
  },
  {
    label: "XML",
    value: "txt/xml",
  },
  {
    label: "CSV",
    value: "txt/csv",
  },
];
export const scheduleTypes = [
  {
    value: 1,
    label: "Once"
  },
  {
    value: 3,
    label: "Hourly"
  },
  {
    value: 4,
    label: "Daily"
  },
  {
    value: 6,
    label: "Weekly"
  },
  {
    value: 7,
    label: "Monthly"
  }
]

export const frequencyValues = [
  {
    label: "1 hour",
    value: 1
  },
  {
    label: "2 hours",
    value: 2
  },
  {
    label: "3 hours",
    value: 3
  },
  {
    label: "4 hours",
    value: 4
  },
  {
    label: "6 hours",
    value: 6
  },
  {
    label: "12 hours",
    value: 12
  },
]

export const propertyAccessorInformation = `
In the example below, the 'Property accessor' would be 'data.items'.

{
  "data": {
      "items": [
          {
              "title": "Example title 1",
              "description": "Example description 1"
          },
          {
              "title": "Example title 2",
              "description": "Example description 2"
          }
      ]
  }
`;